 .circle { 
    /* width: 100px;
    height: 100px; */
     width: 15%;
    height: 22%;
    border-radius: 50%;
    background-color: white;
    position: absolute;  
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
  } 
  
  .circle:hover {
    background-color: rgba(0, 0, 0, 0.2);
  }
  
  .message {
    color: black;
    font-weight: bold;
    text-align: center;
  }
  
  .circle-2 {
    position: absolute;
  }
  
  .circle-3 {
    position: absolute;
  }