.group-1000006440 {
  align-items: flex-start;
  display: flex;
  margin-left: 2.0px;
  min-width: 1172px;
}

.overlap-group3 {
  align-items: center;
  display: flex;
  height: 563px;
  min-width: 1170px;
  padding: 0 87px;
}

.weve-trained-a-mode {
  letter-spacing: 0;
  /* line-height: 106.2px; */
  margin-bottom: 19.0px;
  min-height: 368px;
  text-align: center;
  width: 417px;
  float:right;
  margin-right:70px;
  margin-top:350px
}

.line-2 {
  align-self: flex-end;
  height: 562px;
  margin-left: 82px;
  width: 1px;
}

.group-1000006406 {
  height: 370px;
  margin-bottom: 33.04px;
  margin-left: 137px;
  width: 351px;
}

