.landing {
  align-items: center;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 5400px;
  overflow: hidden;
  position: relative;
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}
@media (max-width: 768px) {

}

@media (min-width: 769px) and (max-width: 1200px) {

}

@media (min-width: 1201px) {

}

.link-class{
  color: white !important;
}
.overlap-group23-1 {
  height: 863px;
  margin-left: 4.0px;
  margin-top: -1px;
  position: relative;
  width: 1444px;
}

.mesh2-1 {
  height: 823px;
  /*left: 271px;*/
  position: absolute;
  top: 0;
  width: 1500px;
}

.rectangle-67 {
  height: 4px;
  left: 449px;
  position: absolute;
  top: 279px;
  width: 181px;
}

.title-2 {
  left: 135px;
  letter-spacing: 0;
  line-height: 107.0px;
  position: absolute;
  top: 227px;
  width: 551px;
}

.span1 {
  color: var(--blue);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 71px;
  font-weight: 400;
  text-decoration: underline;
}

.an-ai-solution-to-ma-2 {
  left: 136px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 421px;
  width: 507px;
}

.overlap-group4-1 {
  align-items: center;
  background-color: var(--blue);
  display: flex;
  gap: 11px;
  height: 58px;
  left: 136px;
  min-width: 158px;
  padding: 17.2px 26.0px;
  position: absolute;
  top: 527px;
}

.join-the-beta {
  align-self: flex-end;
  letter-spacing: 0;
  line-height: normal;
  min-height: 19px;
  min-width: 85px;
  text-align: center;
}

.arrow-1 {
  height: 10px;
  margin-bottom: 0.6px;
  width: 10px;
}

.group-1000006551 {
  height: 58px;
  left: 311px;
  position: absolute;
  top: 527px;
  width: 158px;
}

.group-1000006576 {
  align-items: flex-start;
  display: flex;
  gap: 10px;
  height: 19px;
  left: 343px;
  min-width: 97px;
  position: absolute;
  top: 550px;
}

.learn-more {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 19px;
  min-width: 75px;
  text-align: center;
}

.arrow-2 {
  height: 10px;
  margin-top: 1.74px;
  width: 10px;
}

.overlap-group8-1 {
  -webkit-backdrop-filter: blur(12px) brightness(100%);
  align-items: center;
  backdrop-filter: blur(12px) brightness(100%);
  background: linear-gradient(180deg, rgba(200.81, 200.81, 200.81, 0.1) 0%, rgba(119, 119, 119, 0.01) 100%);
  display: flex;
  height: 114px;
  left: 0;
  min-width: 1440px;
  padding: 35.0px 50px;
  position: absolute;
  top: 1px;
}

.group-container-6 {
  align-self: flex-start;
  height: 43px;
  position: relative;
  width: 58px;
}

.overlap-group-11 {
  height: 43px;
  left: 0;
  position: absolute;
  top: 0;
  width: 58px;
}

.vector-24-4 {
  height: 6px;
  left: 37px;
  position: absolute;
  top: 0;
  width: 7px;
}

.rectangle-95-4 {
  height: 38px;
  left: 8px;
  position: absolute;
  top: 0;
  width: 29px;
}

.vector-25-4 {
  height: 8px;
  left: 50px;
  position: absolute;
  top: 12px;
  width: 9px;
}

.vector-23-4 {
  height: 9px;
  left: 0;
  position: absolute;
  top: 17px;
  width: 10px;
}

.rectangle-96-4 {
  background-color: var(--blue);
  height: 39px;
  left: 21px;
  position: absolute;
  top: 5px;
  width: 29px;
}

.group-1000006420-4 {
  height: 41px;
  left: 4px;
  position: absolute;
  top: 1px;
  width: 51px;
}

.company-2 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 856px;
  margin-top: 6.0px;
  min-height: 22px;
  min-width: 71px;
  text-align: center;
}

.group-1000006484-2 {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  height: 22px;
  margin-left: 28px;
  margin-top: 8.0px;
  min-width: 77px;
}

.product-2 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 22px;
  min-width: 123px;
  text-align: center;
}

.vector-34-2 {
  align-self: center;
  height: 5px;
  margin-bottom: 5.0px;
  width: 9px;
}

.place-2 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 28px;
  margin-top: 6.0px;
  min-height: 22px;
  min-width: 69px;
  text-align: center;
}

.frame-11-2 {
  align-items: flex-start;
  /* display: flex; */
  height: 42px;
  margin-left: 28px;
  padding: 12.6px 31px;
  width: 117px;
  border: 1px solid var(--ship-gray);
}

.sign-up-2 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 14px;
  text-align: center;
  width: 55px;
}

.overlap-group-container-3 {
  align-items: flex-end;
  background: linear-gradient(180deg, rgb(211.44, 228.86, 255) 0%, rgba(231.62, 240.97, 255, 0.91) 29.69%, rgba(227.37, 238.42, 255, 0.91) 100%);
  display: flex;
  flex-direction: column;
  gap: 72px;
  min-height: 1509px;
  padding: 132.2px 136.2px;
  width: 1442px;
}

.overlap-group2-3 {
  height: 586px;
  position: relative;
  width: 1169px;
}


.overlap-group-10 {
  align-items: flex-end;
  background-color: var(--white);
  display: flex;
  height: 72px;
  left: 67px;
  min-width: 195px;
  padding: 20.4px 44.4px;
  position: absolute;
  top: 442px;
  z-index: 10;
}

.explore-more {
  letter-spacing: 0;
  line-height: normal;
  min-height: 24px;
  min-width: 106px;
  text-align: center;
}

.rectangle {
  background-color: var(--persian-blue);
  height: 585px;
  left: 0;
  position: absolute;
  top: 0;
  width: 585px;
}

.rectangle25 {
  background-color: var(--persian-blue);
  height: 585px;
  left: 0;

}

.rectangle-1 {
  background-color: var(--white);
  height: 586px;
  left: 584px;
  position: absolute;
  top: 0;
  width: 586px;
}

.energy-savings {
  left: 67px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 74px;
  width: 700px;
  font-size: 38px !important;
}

.foundersgrotesk-regular-normal-turquoise-blue-41px{
  font-size: 38px !important;
}

.design-accessibility {
  left: 67px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  top: 74px;
  width: 372px;
}

.overlap-group21-1 {
  height: 408px;
  left: 615px;
  position: absolute;
  top: 76px;
  width: 542px;
}

.group-1000006585 {
  align-items: center;
  display: flex;
  gap: 1px;
  height: 388px;
  left: 35px;
  min-width: 507px;
  padding: 0 0.0px;
  position: absolute;
  top: 0;
}

.overlap-group-12 {
  height: 388px;
  position: relative;
  width: 430px;
}

.ellipse-62 {
  background-color: var(--melrose-2);
  border-radius: 43.5px/44px;
  height: 88px;
  left: 176px;
  position: absolute;
  top: 49px;
  width: 87px;
}

.ellipse-65 {
  height: 49px;
  left: 220px;
  position: absolute;
  top: 89px;
  width: 44px;
}

.ellipse-63 {
  background-color: var(--melrose-2);
  border-radius: 27.5px/27px;
  height: 54px;
  left: 162px;
  position: absolute;
  top: 31px;
  width: 55px;
}

.ellipse-64 {
  background-color: #769dff99;
  border-radius: 20px;
  height: 40px;
  left: 343px;
  position: absolute;
  top: 262px;
  width: 40px;
}

.vector-38 {
  height: 114px;
  left: 0;
  position: absolute;
  top: 88px;
  width: 429px;
}

.vector-39 {
  height: 114px;
  left: 0;
  position: absolute;
  top: 167px;
  width: 429px;
}

.vector-40 {
  height: 114px;
  left: 0;
  position: absolute;
  top: 241px;
  width: 429px;
}

.vector-41 {
  height: 34px;
  left: 182px;
  position: absolute;
  top: 339px;
  width: 125px;
}

.vector-42 {
  height: 27px;
  left: 198px;
  position: absolute;
  top: 361px;
  width: 110px;
}

.group-1000006580-1 {
  height: 167px;
  left: 242px;
  position: absolute;
  top: 195px;
  width: 7px;
}

.group-1000006581 {
  height: 167px;
  left: 0;
  position: absolute;
  top: 199px;
  width: 7px;
}

.group-1000006582 {
  height: 158px;
  left: 218px;
  position: absolute;
  top: 85px;
  width: 7px;
}

.group-1000006583 {
  height: 158px;
  left: 423px;
  position: absolute;
  top: 85px;
  width: 7px;
}

.line-758 {
  height: 313px;
  left: 154px;
  position: absolute;
  top: 0;
  width: 235px;
}

.line-760 {
  height: 143px;
  left: 0;
  position: absolute;
  top: 76px;
  width: 263px;
}

.line-761 {
  height: 143px;
  left: 9px;
  position: absolute;
  top: 150px;
  width: 263px;
}

.line-762 {
  height: 143px;
  left: 4px;
  position: absolute;
  top: 228px;
  width: 263px;
}

.line-763 {
  height: 125px;
  left: 224px;
  position: absolute;
  top: 235px;
  width: 199px;
}

.line-764 {
  height: 125px;
  left: 226px;
  position: absolute;
  top: 160px;
  width: 199px;
}

.line-765 {
  height: 125px;
  left: 230px;
  position: absolute;
  top: 80px;
  width: 199px;
}

.line-766 {
  height: 3px;
  left: 198px;
  position: absolute;
  top: 97px;
  width: 228px;
}

.line-767 {
  height: 3px;
  left: 194px;
  position: absolute;
  top: 174px;
  width: 228px;
}

.line-768 {
  height: 3px;
  left: 195px;
  position: absolute;
  top: 249px;
  width: 228px;
}

.line-769 {
  height: 4px;
  left: 9px;
  position: absolute;
  top: 340px;
  width: 254px;
}

.line-771 {
  height: 4px;
  left: 9px;
  position: absolute;
  top: 190px;
  width: 254px;
}

.line-770 {
  height: 3px;
  left: 9px;
  position: absolute;
  top: 270px;
  width: 254px;
}

.overlap-group1-2 {
  height: 32px;
  margin-bottom: 153.11px;
  position: relative;
  width: 74px;
}

.ellipse-66 {
  background-color: #0015c1cc;
  border-radius: 4.5px;
  height: 9px;
  left: 0;
  position: absolute;
  top: 23px;
  width: 9px;
}

.ellipse-67 {
  border-radius: 4.5px;
  height: 9px;
  left: 48px;
  position: absolute;
  top: 23px;
  width: 9px;
}

.line-759 {
  height: 1px;
  left: 9px;
  position: absolute;
  top: 28px;
  width: 40px;
}

.number {
  left: 16px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 0;
  width: 58px;
}

.ellipse-68 {
  background-color: #3344cd;
  border-radius: 4.5px;
  height: 9px;
  left: 41px;
  position: absolute;
  top: 399px;
  width: 9px;
}

.vector-43 {
  height: 86px;
  left: 0;
  position: absolute;
  top: 317px;
  width: 43px;
}

.ellipse-69 {
  border-radius: 4px;
  height: 8px;
  left: 14px;
  position: absolute;
  top: 314px;
  width: 8px;
}

.group-container-7 {
  align-items: flex-start;
  display: flex;
  gap: 51px;
  height: 242px;
  margin-right: 0;
  margin-top: 123px;
  min-width: 1168px;
}

.overlap-group3-1 {
  align-items: flex-start;
  background: linear-gradient(180deg, rgb(243.31, 245.22, 255) 0%, rgba(247.56, 248.78, 255, 0) 100%);
  display: flex;
  flex-direction: column;
  min-height: 242px;
  padding: 26.0px 28.0px;
  width: 355px;
}

.overlap-group-13 {
  align-items: flex-end;
  background-color: var(--portage-2);
  display: flex;
  height: 59px;
  justify-content: flex-end;
  min-width: 59px;
  padding: 17.7px 10.0px;
}

.group-1000006478 {
  height: 22px;
  width: 36px;
}

.save-millions-of-dol {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 0;
  margin-top: 29px;
  min-height: 46px;
  width: 299px;
}

.learn-more-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 0;
  margin-top: 21px;
  min-height: 23px;
}

.group-100000648 {
  align-items: flex-start;
  background: linear-gradient(180deg, rgb(243.31, 245.22, 255) 0%, rgba(247.56, 248.78, 255, 0) 100%);
  display: flex;
  flex-direction: column;
  min-height: 242px;
  padding: 30.1px 28.0px;
  width: 355px;
}

.group-1000006545 {
  background: linear-gradient(180deg, rgba(71.79, 91.55, 249.69, 0.11) 0%, rgba(153.58, 164.38, 250.75, 0.1) 100%);
  height: 59px;
  margin-top: 4px;
  position: relative;
  width: 59px;
}

.rectangle-155 {
  background-color: #afcdff;
  height: 12px;
  left: 28px;
  position: absolute;
  top: 35px;
  width: 3px;
}

.vector {
  height: 22px;
  left: 17px;
  position: absolute;
  top: 13px;
  width: 26px;
}

.btai-has-saved-two,
.take-a-glance-into-o {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 28px;
  min-height: 46px;
  width: 299px;
}

.heres-how-we-did-it,
.take-a-peek {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 22px;
  min-height: 23px;
}

.group-1000006543 {
  align-items: flex-end;
  background-color: var(--portage-2);
  display: flex;
  height: 59px;
  margin-top: 4px;
  min-width: 59px;
  padding: 12.9px 10.6px;
}

.subtract-container-1 {
  height: 32px;
  position: relative;
  width: 38px;
}

.subtract-3 {
  height: 32px;
  left: 0;
  position: absolute;
  top: 0;
  width: 37px;
}

.subtract-4 {
  height: 30px;
  left: 3px;
  position: absolute;
  top: 1px;
  width: 35px;
}

.group-1000006577 {
  align-items: flex-start;
  display: flex;
  margin-top: 122px;
  min-width: 1168px;
}

.group-container-8 {
  height: 2098px;
  position: relative;
  width: 1168px;
}

.group-1000006547 {
  align-items: center;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  left: 0;
  min-height: 2098px;
  padding: 77px 110px;
  position: absolute;
  top: 0;
  width: 1168px;
}

.we-a-container {
  -webkit-backdrop-filter: blur(15px) brightness(100%);
  align-items: flex-end;
  backdrop-filter: blur(15px) brightness(100%);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.04) 100%);
  display: flex;
  flex-direction: column;
  gap: 40px;
  min-height: 368px;
  padding: 60px 98px;
  width: 946px;
}

.who-we-are {
  align-self: center;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 14.0px;
  min-height: 47px;
  min-width: 194px;
  text-align: center;
}

.founded-at-usc-we-a {
  letter-spacing: 0;
  line-height: normal;
  min-height: 126px;
  text-align: center;
  width: 749px;
}

.line-735 {
  align-self: flex-end;
  height: 1px;
  margin-top: 104px;
  width: 946px;
}

.overlap-group-14 {
  height: 90px;
  margin-right: 1.0px;
  margin-top: 103px;
  position: relative;
  width: 289px;
}

.meet-the-team {
  left: 53px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 31px;
  /* width: 186px; */
}

.rectangle-62 {
  height: 90px;
  left: 0;
  position: absolute;
  top: 0;
  width: 289px;
  border: 1px solid white;
}

.parent {
  display: flex;
  flex-wrap: wrap;
}

.group-container-9 {
  height: 389px;
  /* left: 111px; */
  left: 3px;
  position: absolute;
  background-image: url('JakePro.jpg');
  background-size: contain;
  background-position: center;
  top: 794px;
  width: 289px;
  transform: scale(.85);

  flex-basis: 25%;
  /* background-image:url('Jake.jpg');
  background-size:contain;
  background-position: center; */
}
.group-container-5 {
  height: 389px;
  left: 0;
  position: absolute;
  top: 0;
  width: 289px;
}
.group-container-15 {
  height: 389px;
  /* left: 440px; */
  left: 293px;
  position: absolute;
  background-image: url('JC.jpg');
  background-size: contain;
  background-position: center;
  top: 794px;
  width: 289px;
  transform: scale(.85);
  flex-basis: 25%;
  /* background-image:url('JC.jpg');
  background-size:contain;
  background-position: center; */
}
.group-container-17 {
  height: 389px;
  left: 588px;
  position: absolute;
  background-image: url('Roy.jpg');
  background-size: contain;
  background-position: center;
  /* top: 1223px; */
  top: 794px;
  width: 289px;
  transform: scale(.85);
  /* background-image:url('Roy.jpg');
  background-size:contain;
  background-position: center; */
  flex-basis: 25%;
}
.group-container-21 {
  height: 389px;
  left: 878px;
  position: absolute;
  background-image: url('Mandika.jpg');
  background-size: contain;
  background-position: center;
  top: 794px;
  width: 289px;
  transform: scale(.85);
  /* background-image:url('Kyle.jpg');
  background-size:contain;
  background-position: center;
  flex-basis: 25%; */

}


.group-1000006578 {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.18) 0%, rgba(255, 255, 255, 0.12) 100%);
  height: 292px;
  left: 0;
  position: absolute;
  top: 0;
  width: 289px;
}

.group-1000006580 {
  background: linear-gradient(178deg, #dadcde,#6483e1);
  height: 99px;
  left: 0;
  position: absolute;
  top: 290px;
  width: 289px;
}

.group-container-10 {
  height: 389px;
  left: 111px;
   position: absolute;
  display:none;
  background-image: url('Jake.jpg');
  background-size: contain;
  background-position: center;
  top: 2081px;
  width: 289px;
}

.group-container-11 {
  height: 389px;
  left: 0px;
  position: absolute;
  background-image: url('Roman.jpg');
  background-size: contain;
  background-position: center;
  top: 1223px;
  width: 289px;
  transform: scale(.85);
  /* background-image:url('Roman.jpg');
  background-size:contain;
  background-position: center; */
}
.group-container-112 {
  height: 389px;
  left: 293px;
  position: absolute;
  background-image: url('Jane.jpg');
  background-size: contain;
  background-position: center;
  top: 1223px;
  width: 289px;
  transform: scale(.85);
  /* background-image:url('Roman.jpg');
  background-size:contain;
  background-position: center; */
}

.group-container-12 {
  height: 389px;
  left: 111px;
  position: absolute;
  display:none;
  top: 2510px;
  width: 289px;
}

.group-container-13 {
  height: 389px;
  left: 111px;
  position: absolute;
  background-image: url('Reagan.jpg');
  background-size: contain;
  background-position: center;
  top: 1652px;
  width: 289px;
}
.group-container-132 {
  height: 389px;
  left: 588px;
  position: absolute;
  background-image: url('Ziare.jpg');
  background-size: contain;
  background-position: center;
  top: 1223px;
  width: 289px;
  transform: scale(.85);
}

.group-container-14 {
  height: 389px;
  left: 111px;
   position: absolute;
  display:none;
  top: 2939px;
  width: 289px;
}


.group-container-16 {
  height: 389px;
  left: 440px;
   position: absolute;
  display:none;
  top: 2081px;
  width: 289px;
}


.group-container-18 {
  height: 389px;
  left: 440px;
   position: absolute;
  display:none;
  top: 2510px;
  width: 289px;
}

.group-container-19 {
  height: 389px;
  left: 440px;
  position: absolute;
  background-image: url('Lily.jpg');
  background-size: contain;
  background-position: center;
  top: 1652px;
  width: 289px;
  /*background-image:url('Jake.jpg');*/
  /*background-size:contain;*/
  /*background-position: center;*/
}

.group-container-20 {
  height: 389px;
  left: 440px;
   position: absolute;
  display:none;
  top: 2939px;
  width: 289px;
}


.group-container-22 {
  height: 389px;
  left: 769px;
   position: absolute;
  display:none;
  top: 2081px;
  width: 289px;
}

.group-container-23 {
  height: 389px;
  left: 878px;
  position: absolute;
  background-image: url('Kyle.jpg');
  background-size: contain;
  background-position: center;
  top: 1223px;
  width: 289px;
  transform: scale(.85);
}

.group-container-24 {
  height: 389px;
  left: 769px;
   position: absolute;
  display:none;
  top: 2510px;
  width: 289px;
}

.group-container-25 {
  height: 389px;
  left: 769px;
  position: absolute;
  background-image: url('Luke.jpg');
  background-size: contain;
  background-position: center;
  top: 1652px;
  width: 289px;
  background-image:url('Luke.jpg');
  background-size:contain;
  background-position: center;
}

.group-container-26 {
  height: 389px;
  left: 769px;
   position: absolute;
  display:none;
  top: 2939px;
  width: 289px;
}

.group-9 {
  align-self: flex-end;
  height: 57px;
  margin-right: 157.64px;
  margin-top: 642px;
  width: 117px;
}

