@import "https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css";
@import "https://px.animaapp.com/642cf3b4870454d79572a153.642cf3b50cd9f12f63dbb07e.s7AAvnh.rcp.png";
@font-face {
  font-family: Founders Grotesk-Regular;
  font-style: normal;
  font-weight: 400;
  src: url("FoundersGrotesk-Regular.73b97c55.ttf") format("truetype");
}

@font-face {
  font-family: Founders Grotesk-Light;
  font-style: normal;
  font-weight: 300;
  src: url("FoundersGrotesk-Light.37d017da.otf") format("opentype");
}

.screen a {
  text-decoration: none;
  display: contents;
}

.container-center-horizontal {
  pointer-events: none;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  display: flex;
}

.container-center-horizontal > * {
  pointer-events: auto;
  flex-shrink: 0;
}

* {
  box-sizing: border-box;
}

.superior {
  border: 1px;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  height: 1435px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  position: relative;
  overflow: hidden;
}

a:any-link {
  text-decoration: none;
}

.frame-61 {
  align-items: flex-start;
  margin-left: 30px;
  display: flex;
  overflow: hidden;
}

.curry {
  color: var(--blueberry) !important;
}

.submit {
  background-color: var(--blueberry);
  border: none;
  margin-top: auto;
  text-decoration: none;
}

.overlap-group19 {
  align-self: flex-start;
  width: 1546px;
  max-width: 500px;
  height: 750px;
  margin: 0 auto;
  position: fixed;
}

.rectangle-176 {
  background: linear-gradient(#6273ff 0%, #8d96ddcc 100%);
  width: 1457px;
  height: 537px;
  position: absolute;
  top: 50px;
  left: 32px;
}

.ellipse-81 {
  border-radius: 269.5px / 275.5px;
  width: 539px;
  height: 551px;
  position: absolute;
  top: 107px;
  left: 0;
}

.line-785 {
  width: 1px;
  height: 539px;
  position: absolute;
  top: 155px;
  left: 421px;
}

.line-786 {
  width: 539px;
  height: 1px;
  position: absolute;
  top: 383px;
  left: 0;
}

.rectangle-179 {
  width: 484px;
  height: 462px;
  position: absolute;
  top: 152px;
  left: 55px;
}

.line-787 {
  width: 1011px;
  height: 1px;
  position: absolute;
  top: 153px;
  left: 535px;
}

.line-788 {
  width: 1px;
  height: 131px;
  position: absolute;
  top: 24px;
  left: 539px;
}

.ellipse-82 {
  background-color: var(--white);
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 377px;
  left: 415px;
}

.ellipse-83 {
  background-color: var(--white);
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 146px;
  left: 533px;
}

.ellipse-86 {
  background-color: var(--white);
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 147px;
  left: 1383px;
}

.ellipse-87 {
  background-color: var(--white);
  border-radius: 5px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 327px;
  left: 1172px;
}

.line-789 {
  width: 4px;
  height: 750px;
  position: absolute;
  top: 0;
  left: 1388px;
}

.ellipse-84 {
  border-radius: 189.92px;
  width: 380px;
  height: 380px;
  position: absolute;
  top: 34px;
  left: 1142px;
}

.ellipse-85 {
  border-radius: 120.2px;
  width: 240px;
  height: 240px;
  position: absolute;
  top: 269px;
  left: 986px;
}

.line-790 {
  width: 599px;
  height: 499px;
  position: absolute;
  top: 37px;
  left: 930px;
}

.overlap-group17 {
  background-color: var(--white);
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  width: 637px;
  min-height: 298px;
  padding: 52px 55px;
  display: flex;
  position: absolute;
  top: 396px;
  left: 451px;
  box-shadow: 0 4px 25px -6px #1033684a;
}

.want-to-know-more {
  -webkit-text-fill-color: transparent;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(#8391ff 0%, #6b9efffc 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 345px;
  min-height: 48px;
  line-height: normal;
  -webkit-background-clip: text !important;
}

.group-1000006588 {
  align-items: flex-start;
  gap: 24px;
  min-width: 463px;
  height: 132px;
  display: flex;
}

.flex-col {
  flex-direction: column;
  align-items: flex-start;
  gap: 31px;
  width: 305px;
  min-height: 133px;
  margin-top: -1px;
  display: flex;
}

.join-the-waitlist {
  letter-spacing: 0;
  width: 209px;
  min-height: 65px;
  line-height: normal;
}

.enter-your-email-address {
  letter-spacing: 0;
  width: 230px;
  min-height: 22px;
  margin-left: 17px;
  line-height: normal;
}

.overlap-group-2 {
  background-color: var(--blueberry);
  align-self: flex-end;
  align-items: flex-end;
  min-width: 130px;
  height: 54px;
  padding: 15px 31px;
  display: flex;
}

.submit {
  letter-spacing: 0;
  text-align: center;
  width: 68px;
  min-height: 22px;
  line-height: normal;
}

.landing {
  background-color: var(--white);
  border: 1px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1440px;
  height: 5400px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.link-class {
  color: #fff !important;
}

.overlap-group23-1 {
  width: 1444px;
  height: 863px;
  margin-top: -1px;
  margin-left: 4px;
  position: relative;
}

.mesh2-1 {
  width: 1500px;
  height: 823px;
  position: absolute;
  top: 0;
}

.rectangle-67 {
  width: 181px;
  height: 4px;
  position: absolute;
  top: 279px;
  left: 449px;
}

.title-2 {
  letter-spacing: 0;
  width: 551px;
  line-height: 107px;
  position: absolute;
  top: 227px;
  left: 135px;
}

.span1 {
  color: var(--blue);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 71px;
  font-weight: 400;
  text-decoration: underline;
}

.an-ai-solution-to-ma-2 {
  letter-spacing: 0;
  width: 507px;
  line-height: normal;
  position: absolute;
  top: 421px;
  left: 136px;
}

.overlap-group4-1 {
  background-color: var(--blue);
  align-items: center;
  gap: 11px;
  min-width: 158px;
  height: 58px;
  padding: 17.2px 26px;
  display: flex;
  position: absolute;
  top: 527px;
  left: 136px;
}

.join-the-beta {
  letter-spacing: 0;
  text-align: center;
  align-self: flex-end;
  min-width: 85px;
  min-height: 19px;
  line-height: normal;
}

.arrow-1 {
  width: 10px;
  height: 10px;
  margin-bottom: .6px;
}

.group-1000006551 {
  width: 158px;
  height: 58px;
  position: absolute;
  top: 527px;
  left: 311px;
}

.group-1000006576 {
  align-items: flex-start;
  gap: 10px;
  min-width: 97px;
  height: 19px;
  display: flex;
  position: absolute;
  top: 550px;
  left: 343px;
}

.learn-more {
  letter-spacing: 0;
  text-align: center;
  min-width: 75px;
  min-height: 19px;
  margin-top: -1px;
  line-height: normal;
}

.arrow-2 {
  width: 10px;
  height: 10px;
  margin-top: 1.74px;
}

.overlap-group8-1 {
  backdrop-filter: blur(12px) brightness();
  background: linear-gradient(#c9c9c91a 0%, #77777703 100%);
  align-items: center;
  min-width: 1440px;
  height: 114px;
  padding: 35px 50px;
  display: flex;
  position: absolute;
  top: 1px;
  left: 0;
}

.group-container-6 {
  align-self: flex-start;
  width: 58px;
  height: 43px;
  position: relative;
}

.overlap-group-11 {
  width: 58px;
  height: 43px;
  position: absolute;
  top: 0;
  left: 0;
}

.vector-24-4 {
  width: 7px;
  height: 6px;
  position: absolute;
  top: 0;
  left: 37px;
}

.rectangle-95-4 {
  width: 29px;
  height: 38px;
  position: absolute;
  top: 0;
  left: 8px;
}

.vector-25-4 {
  width: 9px;
  height: 8px;
  position: absolute;
  top: 12px;
  left: 50px;
}

.vector-23-4 {
  width: 10px;
  height: 9px;
  position: absolute;
  top: 17px;
  left: 0;
}

.rectangle-96-4 {
  background-color: var(--blue);
  width: 29px;
  height: 39px;
  position: absolute;
  top: 5px;
  left: 21px;
}

.group-1000006420-4 {
  width: 51px;
  height: 41px;
  position: absolute;
  top: 1px;
  left: 4px;
}

.company-2 {
  letter-spacing: 0;
  text-align: center;
  min-width: 71px;
  min-height: 22px;
  margin-top: 6px;
  margin-left: 856px;
  line-height: normal;
}

.group-1000006484-2 {
  align-items: flex-start;
  gap: 8px;
  min-width: 77px;
  height: 22px;
  margin-top: 8px;
  margin-left: 28px;
  display: flex;
}

.product-2 {
  letter-spacing: 0;
  text-align: center;
  min-width: 123px;
  min-height: 22px;
  margin-top: -1px;
  line-height: normal;
}

.vector-34-2 {
  align-self: center;
  width: 9px;
  height: 5px;
  margin-bottom: 5px;
}

.place-2 {
  letter-spacing: 0;
  text-align: center;
  min-width: 69px;
  min-height: 22px;
  margin-top: 6px;
  margin-left: 28px;
  line-height: normal;
}

.frame-11-2 {
  border: 1px solid var(--ship-gray);
  align-items: flex-start;
  width: 117px;
  height: 42px;
  margin-left: 28px;
  padding: 12.6px 31px;
}

.sign-up-2 {
  letter-spacing: 0;
  text-align: center;
  width: 55px;
  min-height: 14px;
  line-height: normal;
}

.overlap-group-container-3 {
  background: linear-gradient(#d3e5ff 0%, #e8f1ffe8 29.69%, #e3eeffe8 100%);
  flex-direction: column;
  align-items: flex-end;
  gap: 72px;
  width: 1442px;
  min-height: 1509px;
  padding: 132.2px 136.2px;
  display: flex;
}

.overlap-group2-3 {
  width: 1169px;
  height: 586px;
  position: relative;
}

.overlap-group-10 {
  background-color: var(--white);
  z-index: 10;
  align-items: flex-end;
  min-width: 195px;
  height: 72px;
  padding: 20.4px 44.4px;
  display: flex;
  position: absolute;
  top: 442px;
  left: 67px;
}

.explore-more {
  letter-spacing: 0;
  text-align: center;
  min-width: 106px;
  min-height: 24px;
  line-height: normal;
}

.rectangle {
  background-color: var(--persian-blue);
  width: 585px;
  height: 585px;
  position: absolute;
  top: 0;
  left: 0;
}

.rectangle25 {
  background-color: var(--persian-blue);
  height: 585px;
  left: 0;
}

.rectangle-1 {
  background-color: var(--white);
  width: 586px;
  height: 586px;
  position: absolute;
  top: 0;
  left: 584px;
}

.energy-savings {
  letter-spacing: 0;
  width: 700px;
  line-height: normal;
  position: absolute;
  top: 74px;
  left: 67px;
  font-size: 38px !important;
}

.foundersgrotesk-regular-normal-turquoise-blue-41px {
  font-size: 38px !important;
}

.design-accessibility {
  letter-spacing: 0;
  width: 372px;
  line-height: normal;
  position: absolute;
  top: 74px;
  left: 67px;
}

.overlap-group21-1 {
  width: 542px;
  height: 408px;
  position: absolute;
  top: 76px;
  left: 615px;
}

.group-1000006585 {
  align-items: center;
  gap: 1px;
  min-width: 507px;
  height: 388px;
  padding: 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 35px;
}

.overlap-group-12 {
  width: 430px;
  height: 388px;
  position: relative;
}

.ellipse-62 {
  background-color: var(--melrose-2);
  border-radius: 43.5px / 44px;
  width: 87px;
  height: 88px;
  position: absolute;
  top: 49px;
  left: 176px;
}

.ellipse-65 {
  width: 44px;
  height: 49px;
  position: absolute;
  top: 89px;
  left: 220px;
}

.ellipse-63 {
  background-color: var(--melrose-2);
  border-radius: 27.5px / 27px;
  width: 55px;
  height: 54px;
  position: absolute;
  top: 31px;
  left: 162px;
}

.ellipse-64 {
  background-color: #769dff99;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 262px;
  left: 343px;
}

.vector-38 {
  width: 429px;
  height: 114px;
  position: absolute;
  top: 88px;
  left: 0;
}

.vector-39 {
  width: 429px;
  height: 114px;
  position: absolute;
  top: 167px;
  left: 0;
}

.vector-40 {
  width: 429px;
  height: 114px;
  position: absolute;
  top: 241px;
  left: 0;
}

.vector-41 {
  width: 125px;
  height: 34px;
  position: absolute;
  top: 339px;
  left: 182px;
}

.vector-42 {
  width: 110px;
  height: 27px;
  position: absolute;
  top: 361px;
  left: 198px;
}

.group-1000006580-1 {
  width: 7px;
  height: 167px;
  position: absolute;
  top: 195px;
  left: 242px;
}

.group-1000006581 {
  width: 7px;
  height: 167px;
  position: absolute;
  top: 199px;
  left: 0;
}

.group-1000006582 {
  width: 7px;
  height: 158px;
  position: absolute;
  top: 85px;
  left: 218px;
}

.group-1000006583 {
  width: 7px;
  height: 158px;
  position: absolute;
  top: 85px;
  left: 423px;
}

.line-758 {
  width: 235px;
  height: 313px;
  position: absolute;
  top: 0;
  left: 154px;
}

.line-760 {
  width: 263px;
  height: 143px;
  position: absolute;
  top: 76px;
  left: 0;
}

.line-761 {
  width: 263px;
  height: 143px;
  position: absolute;
  top: 150px;
  left: 9px;
}

.line-762 {
  width: 263px;
  height: 143px;
  position: absolute;
  top: 228px;
  left: 4px;
}

.line-763 {
  width: 199px;
  height: 125px;
  position: absolute;
  top: 235px;
  left: 224px;
}

.line-764 {
  width: 199px;
  height: 125px;
  position: absolute;
  top: 160px;
  left: 226px;
}

.line-765 {
  width: 199px;
  height: 125px;
  position: absolute;
  top: 80px;
  left: 230px;
}

.line-766 {
  width: 228px;
  height: 3px;
  position: absolute;
  top: 97px;
  left: 198px;
}

.line-767 {
  width: 228px;
  height: 3px;
  position: absolute;
  top: 174px;
  left: 194px;
}

.line-768 {
  width: 228px;
  height: 3px;
  position: absolute;
  top: 249px;
  left: 195px;
}

.line-769 {
  width: 254px;
  height: 4px;
  position: absolute;
  top: 340px;
  left: 9px;
}

.line-771 {
  width: 254px;
  height: 4px;
  position: absolute;
  top: 190px;
  left: 9px;
}

.line-770 {
  width: 254px;
  height: 3px;
  position: absolute;
  top: 270px;
  left: 9px;
}

.overlap-group1-2 {
  width: 74px;
  height: 32px;
  margin-bottom: 153.11px;
  position: relative;
}

.ellipse-66 {
  background-color: #0015c1cc;
  border-radius: 4.5px;
  width: 9px;
  height: 9px;
  position: absolute;
  top: 23px;
  left: 0;
}

.ellipse-67 {
  border-radius: 4.5px;
  width: 9px;
  height: 9px;
  position: absolute;
  top: 23px;
  left: 48px;
}

.line-759 {
  width: 40px;
  height: 1px;
  position: absolute;
  top: 28px;
  left: 9px;
}

.number {
  letter-spacing: 0;
  text-align: center;
  width: 58px;
  line-height: normal;
  position: absolute;
  top: 0;
  left: 16px;
}

.ellipse-68 {
  background-color: #3344cd;
  border-radius: 4.5px;
  width: 9px;
  height: 9px;
  position: absolute;
  top: 399px;
  left: 41px;
}

.vector-43 {
  width: 43px;
  height: 86px;
  position: absolute;
  top: 317px;
  left: 0;
}

.ellipse-69 {
  border-radius: 4px;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 314px;
  left: 14px;
}

.group-container-7 {
  align-items: flex-start;
  gap: 51px;
  min-width: 1168px;
  height: 242px;
  margin-top: 123px;
  margin-right: 0;
  display: flex;
}

.overlap-group3-1 {
  background: linear-gradient(#f3f5ff 0%, #f8f9ff00 100%);
  flex-direction: column;
  align-items: flex-start;
  width: 355px;
  min-height: 242px;
  padding: 26px 28px;
  display: flex;
}

.overlap-group-13 {
  background-color: var(--portage-2);
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 59px;
  height: 59px;
  padding: 17.7px 10px;
  display: flex;
}

.group-1000006478 {
  width: 36px;
  height: 22px;
}

.save-millions-of-dol {
  letter-spacing: 0;
  width: 299px;
  min-height: 46px;
  margin-top: 29px;
  margin-left: 0;
  line-height: normal;
}

.learn-more-1 {
  letter-spacing: 0;
  min-height: 23px;
  margin-top: 21px;
  margin-left: 0;
  line-height: normal;
}

.group-100000648 {
  background: linear-gradient(#f3f5ff 0%, #f8f9ff00 100%);
  flex-direction: column;
  align-items: flex-start;
  width: 355px;
  min-height: 242px;
  padding: 30.1px 28px;
  display: flex;
}

.group-1000006545 {
  background: linear-gradient(#485cfa1c 0%, #9aa4fb1a 100%);
  width: 59px;
  height: 59px;
  margin-top: 4px;
  position: relative;
}

.rectangle-155 {
  background-color: #afcdff;
  width: 3px;
  height: 12px;
  position: absolute;
  top: 35px;
  left: 28px;
}

.vector {
  width: 26px;
  height: 22px;
  position: absolute;
  top: 13px;
  left: 17px;
}

.btai-has-saved-two, .take-a-glance-into-o {
  letter-spacing: 0;
  width: 299px;
  min-height: 46px;
  margin-top: 28px;
  line-height: normal;
}

.heres-how-we-did-it, .take-a-peek {
  letter-spacing: 0;
  min-height: 23px;
  margin-top: 22px;
  line-height: normal;
}

.group-1000006543 {
  background-color: var(--portage-2);
  align-items: flex-end;
  min-width: 59px;
  height: 59px;
  margin-top: 4px;
  padding: 12.9px 10.6px;
  display: flex;
}

.subtract-container-1 {
  width: 38px;
  height: 32px;
  position: relative;
}

.subtract-3 {
  width: 37px;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
}

.subtract-4 {
  width: 35px;
  height: 30px;
  position: absolute;
  top: 1px;
  left: 3px;
}

.group-1000006577 {
  align-items: flex-start;
  min-width: 1168px;
  margin-top: 122px;
  display: flex;
}

.group-container-8 {
  width: 1168px;
  height: 2098px;
  position: relative;
}

.group-1000006547 {
  background-size: 100% 100%;
  flex-direction: column;
  align-items: center;
  width: 1168px;
  min-height: 2098px;
  padding: 77px 110px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.we-a-container {
  backdrop-filter: blur(15px) brightness();
  background: linear-gradient(#ffffff26 0%, #ffffff0a 100%);
  flex-direction: column;
  align-items: flex-end;
  gap: 40px;
  width: 946px;
  min-height: 368px;
  padding: 60px 98px;
  display: flex;
}

.who-we-are {
  letter-spacing: 0;
  text-align: center;
  align-self: center;
  min-width: 194px;
  min-height: 47px;
  margin-left: 14px;
  line-height: normal;
}

.founded-at-usc-we-a {
  letter-spacing: 0;
  text-align: center;
  width: 749px;
  min-height: 126px;
  line-height: normal;
}

.line-735 {
  align-self: flex-end;
  width: 946px;
  height: 1px;
  margin-top: 104px;
}

.overlap-group-14 {
  width: 289px;
  height: 90px;
  margin-top: 103px;
  margin-right: 1px;
  position: relative;
}

.meet-the-team {
  letter-spacing: 0;
  text-align: center;
  line-height: normal;
  position: absolute;
  top: 31px;
  left: 53px;
}

.rectangle-62 {
  border: 1px solid #fff;
  width: 289px;
  height: 90px;
  position: absolute;
  top: 0;
  left: 0;
}

.parent {
  flex-wrap: wrap;
  display: flex;
}

.group-container-9 {
  background-image: url("JakePro.89e7f555.jpg");
  background-position: center;
  background-size: contain;
  flex-basis: 25%;
  width: 289px;
  height: 389px;
  position: absolute;
  top: 794px;
  left: 3px;
  transform: scale(.85);
}

.group-container-5 {
  width: 289px;
  height: 389px;
  position: absolute;
  top: 0;
  left: 0;
}

.group-container-15 {
  background-image: url("JC.8d6f800e.jpg");
  background-position: center;
  background-size: contain;
  flex-basis: 25%;
  width: 289px;
  height: 389px;
  position: absolute;
  top: 794px;
  left: 293px;
  transform: scale(.85);
}

.group-container-17 {
  background-image: url("Roy.e22d4dae.jpg");
  background-position: center;
  background-size: contain;
  flex-basis: 25%;
  width: 289px;
  height: 389px;
  position: absolute;
  top: 794px;
  left: 588px;
  transform: scale(.85);
}

.group-container-21 {
  background-image: url("Mandika.40807b79.jpg");
  background-position: center;
  background-size: contain;
  width: 289px;
  height: 389px;
  position: absolute;
  top: 794px;
  left: 878px;
  transform: scale(.85);
}

.group-1000006578 {
  background: linear-gradient(#ffffff2e 0%, #ffffff1f 100%);
  width: 289px;
  height: 292px;
  position: absolute;
  top: 0;
  left: 0;
}

.group-1000006580 {
  background: linear-gradient(178deg, #dadcde, #6483e1);
  width: 289px;
  height: 99px;
  position: absolute;
  top: 290px;
  left: 0;
}

.group-container-10 {
  background-image: url("Jake.939e1b85.jpg");
  background-position: center;
  background-size: contain;
  width: 289px;
  height: 389px;
  display: none;
  position: absolute;
  top: 2081px;
  left: 111px;
}

.group-container-11 {
  background-image: url("Roman.fca84a72.jpg");
  background-position: center;
  background-size: contain;
  width: 289px;
  height: 389px;
  position: absolute;
  top: 1223px;
  left: 0;
  transform: scale(.85);
}

.group-container-112 {
  background-image: url("Jane.9a407e36.jpg");
  background-position: center;
  background-size: contain;
  width: 289px;
  height: 389px;
  position: absolute;
  top: 1223px;
  left: 293px;
  transform: scale(.85);
}

.group-container-12 {
  width: 289px;
  height: 389px;
  display: none;
  position: absolute;
  top: 2510px;
  left: 111px;
}

.group-container-13 {
  background-image: url("Reagan.49bf37c3.jpg");
  background-position: center;
  background-size: contain;
  width: 289px;
  height: 389px;
  position: absolute;
  top: 1652px;
  left: 111px;
}

.group-container-132 {
  background-image: url("Ziare.6cdb3abf.jpg");
  background-position: center;
  background-size: contain;
  width: 289px;
  height: 389px;
  position: absolute;
  top: 1223px;
  left: 588px;
  transform: scale(.85);
}

.group-container-14 {
  width: 289px;
  height: 389px;
  display: none;
  position: absolute;
  top: 2939px;
  left: 111px;
}

.group-container-16 {
  width: 289px;
  height: 389px;
  display: none;
  position: absolute;
  top: 2081px;
  left: 440px;
}

.group-container-18 {
  width: 289px;
  height: 389px;
  display: none;
  position: absolute;
  top: 2510px;
  left: 440px;
}

.group-container-19 {
  background-image: url("Lily.b7f5d908.jpg");
  background-position: center;
  background-size: contain;
  width: 289px;
  height: 389px;
  position: absolute;
  top: 1652px;
  left: 440px;
}

.group-container-20 {
  width: 289px;
  height: 389px;
  display: none;
  position: absolute;
  top: 2939px;
  left: 440px;
}

.group-container-22 {
  width: 289px;
  height: 389px;
  display: none;
  position: absolute;
  top: 2081px;
  left: 769px;
}

.group-container-23 {
  background-image: url("Kyle.05782c5f.jpg");
  background-position: center;
  background-size: contain;
  width: 289px;
  height: 389px;
  position: absolute;
  top: 1223px;
  left: 878px;
  transform: scale(.85);
}

.group-container-24 {
  width: 289px;
  height: 389px;
  display: none;
  position: absolute;
  top: 2510px;
  left: 769px;
}

.group-container-25 {
  background-image: url("Luke.ff2270c0.jpg");
  background-position: center;
  background-size: contain;
  width: 289px;
  height: 389px;
  position: absolute;
  top: 1652px;
  left: 769px;
}

.group-container-26 {
  width: 289px;
  height: 389px;
  display: none;
  position: absolute;
  top: 2939px;
  left: 769px;
}

.group-9 {
  align-self: flex-end;
  width: 117px;
  height: 57px;
  margin-top: 642px;
  margin-right: 157.64px;
}

.frame-61-2 {
  backdrop-filter: blur(15.99px) brightness();
  background: linear-gradient(#2566ff 0%, #2566ffe0 74.67%);
  align-items: flex-start;
  width: 1440px;
  height: 380px;
  margin-top: 123px;
  display: flex;
  overflow: hidden;
}

.link-class {
  color: #fff !important;
}

.group-container-27 {
  flex-direction: column;
  align-items: flex-start;
  width: 749px;
  min-height: 336px;
  display: flex;
}

.overlap-group2-4 {
  width: 119px;
  height: 119px;
  margin-left: 136px;
  position: relative;
}

.group-container-28 {
  width: 80px;
  height: 59px;
  position: absolute;
  top: 31px;
  left: 20px;
}

.overlap-group-15 {
  width: 80px;
  height: 59px;
  position: absolute;
  top: 0;
  left: 0;
}

.vector-24-5 {
  width: 9px;
  height: 8px;
  position: absolute;
  top: 0;
  left: 50px;
}

.rectangle-95-5 {
  width: 39px;
  height: 51px;
  position: absolute;
  top: 0;
  left: 11px;
}

.vector-25-5 {
  width: 12px;
  height: 11px;
  position: absolute;
  top: 16px;
  left: 68px;
}

.vector-23-5 {
  width: 14px;
  height: 13px;
  position: absolute;
  top: 23px;
  left: 0;
}

.rectangle-96-5 {
  background-color: var(--white);
  width: 40px;
  height: 53px;
  position: absolute;
  top: 6px;
  left: 29px;
}

.group-1000006420-5 {
  width: 70px;
  height: 55px;
  position: absolute;
  top: 2px;
  left: 5px;
}

.rectangle-162-2 {
  width: 119px;
  height: 119px;
  position: absolute;
  top: 0;
  left: 0;
}

.group-1000006570 {
  flex-direction: column;
  align-items: flex-start;
  width: 749px;
  min-height: 104px;
  display: flex;
}

.overlap-group-16 {
  background: linear-gradient(#ffffff21 0%, #ffffff1f 100%);
  align-self: flex-end;
  align-items: flex-end;
  min-width: 613px;
  height: 104px;
  padding: 20px 33px;
  display: flex;
}

.an-ai-solution-to-ma-3 {
  letter-spacing: 0;
  width: 501px;
  min-height: 54px;
}

.line-739 {
  width: 138px;
  height: 1px;
}

.group-1000006403 {
  justify-content: flex-end;
  align-self: center;
  align-items: flex-start;
  gap: 184px;
  min-width: 556px;
  margin-top: 47px;
  margin-left: 79px;
  display: flex;
}

.join-the-waitlist-2 {
  letter-spacing: 0;
  align-self: center;
  width: 177px;
  min-height: 24px;
  margin-top: 5.6px;
  line-height: normal;
}

.overlap-group-17 {
  background-color: var(--white);
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 171px;
  height: 66px;
  padding: 18px 54px;
  display: flex;
}

.sign-up-3 {
  letter-spacing: 0;
  text-align: center;
  min-width: 62px;
  min-height: 24px;
  line-height: normal;
}

.line-19 {
  align-self: flex-end;
  width: 1px;
  height: 383px;
  margin-bottom: -3px;
}

.group-1000006567 {
  flex-direction: column;
  align-self: center;
  align-items: flex-start;
  gap: 47px;
  width: 476px;
  min-height: 282px;
  margin-top: 10px;
  margin-left: 80px;
  display: flex;
}

.group-1000006563 {
  align-items: flex-start;
  min-width: 476px;
  display: flex;
}

.overlap-group1-3 {
  width: 221px;
  height: 169px;
  position: relative;
}

.rectangle-66 {
  width: 181px;
  height: 169px;
  position: absolute;
  top: 0;
  left: 0;
}

.line-736 {
  width: 41px;
  height: 1px;
  position: absolute;
  top: 84px;
  left: 180px;
}

.frame-62 {
  flex-direction: column;
  align-items: center;
  gap: 17px;
  width: 79px;
  min-height: 106px;
  display: flex;
  position: absolute;
  top: 32px;
  left: 51px;
}

.company-3 {
  letter-spacing: 0;
  text-align: center;
  min-width: 79px;
  min-height: 24px;
  margin-top: -1px;
  line-height: normal;
}

.product-3 {
  letter-spacing: 0;
  text-align: center;
  min-width: 64px;
  min-height: 24px;
  line-height: normal;
}

.place-3 {
  letter-spacing: 0;
  text-align: center;
  min-width: 77px;
  min-height: 24px;
  line-height: normal;
}

.overlap-group-18 {
  background: linear-gradient(#fff0 0% 100%);
  border: 1px solid #fff;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  width: 255px;
  min-height: 169px;
  padding: 54.8px 0;
  display: flex;
}

.leed-calculator-2 {
  letter-spacing: 0;
  text-align: center;
  width: 159px;
  min-height: 16px;
  line-height: normal;
}

.ai-product-2 {
  letter-spacing: 0;
  text-align: center;
  width: 94px;
  min-height: 16px;
  line-height: normal;
}

.group-1000006564 {
  align-items: flex-end;
  gap: 25px;
  min-width: 284px;
  height: 66px;
  padding: 16.2px 37px;
  display: flex;
}

.terms {
  letter-spacing: 0;
  text-align: center;
  min-width: 57px;
  min-height: 27px;
  line-height: normal;
}

.privacy-policy {
  letter-spacing: 0;
  text-align: center;
  min-width: 122px;
  min-height: 27px;
  line-height: normal;
}

.group-100000659-1 {
  flex-direction: column;
  align-items: center;
  gap: 14px;
  width: 247px;
  min-height: 56px;
  display: flex;
  position: absolute;
  top: 314px;
  left: 23px;
}

.name {
  letter-spacing: 0;
  text-align: center;
  width: 161px;
  min-height: 21px;
  margin-top: -1px;
  margin-right: 4px;
  line-height: normal;
}

.developer {
  letter-spacing: 0;
  text-align: center;
  width: 243px;
  min-height: 21px;
  margin-right: 4px;
  line-height: normal;
}

.group-100000659-1.group-1000006593 {
  left: 21px;
}

.group-1000006549 {
  flex-direction: column;
  align-items: flex-start;
  width: 483px;
  min-height: 231px;
  display: flex;
  position: absolute;
  top: 162px;
  left: 67px;
}

.the-easiest-design-p {
  letter-spacing: 0;
  min-height: 31px;
  margin-top: -1px;
  line-height: normal;
}

.line-15-1 {
  width: 91px;
  height: 1px;
  margin-top: 23px;
  margin-left: 1px;
}

.although-the-world-s {
  letter-spacing: 0;
  width: 479px;
  min-height: 156px;
  margin-top: 20px;
  line-height: normal;
}

.group-1000006471 {
  flex-direction: column;
  align-items: flex-start;
  width: 457px;
  min-height: 231px;
  display: flex;
  position: absolute;
  top: 162px;
  left: 67px;
}

.energy-consumption-calculator {
  letter-spacing: 0;
  min-height: 31px;
  margin-top: -1px;
  line-height: normal;
}

.line-15 {
  width: 91px;
  height: 1px;
  margin-top: 23px;
  margin-left: 1px;
}

.construction-account {
  letter-spacing: 0;
  width: 453px;
  min-height: 156px;
  margin-top: 20px;
  line-height: normal;
}

.group-1000006470-2 {
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 414px;
  min-height: 369px;
  display: flex;
  position: absolute;
  top: 109px;
  left: 670px;
}

.overlap-group7-2 {
  width: 414px;
  height: 333px;
  position: relative;
}

.frame-20 {
  flex-direction: column;
  align-items: flex-end;
  gap: 49px;
  width: 414px;
  min-height: 333px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.group-1000006463 {
  align-items: flex-start;
  gap: 18px;
  min-width: 416px;
  height: 15px;
  margin-right: -2px;
  display: flex;
}

.number-2 {
  letter-spacing: 0;
  text-align: right;
  min-width: 22px;
  min-height: 15px;
  margin-top: -1.08px;
  line-height: normal;
}

.line {
  align-self: center;
  width: 374px;
  height: 1px;
  margin-top: 1.09px;
}

.group-1000006465 {
  align-items: flex-start;
  gap: 18px;
  min-width: 402px;
  height: 15px;
  margin-right: -2px;
  display: flex;
}

.number-1 {
  letter-spacing: 0;
  text-align: right;
  min-width: 8px;
  min-height: 15px;
  margin-top: -1.08px;
  line-height: normal;
}

.group-1000006459 {
  width: 308px;
  height: 240px;
  position: absolute;
  top: 44px;
  left: 73px;
}

.frame-21 {
  align-items: flex-start;
  gap: 40px;
  min-width: 344px;
  height: 15px;
  margin-left: 33.42px;
  display: flex;
}

.number-3 {
  letter-spacing: 0;
  text-align: right;
  min-width: 6px;
  min-height: 15px;
  margin-top: -1.08px;
  line-height: normal;
}

.dropdown-menu a {
  color: #000;
  background-color: #000;
}

.dropdown-menu .active a {
  background-color: #000;
}

.nav-item a {
  color: #000;
  background-color: #000;
}

.nav-item .active a {
  background-color: #000;
}

.a-navbar, .b-navbar {
  text-align: left;
  color: #000;
  text-decoration: underline;
}

.group-100000646 {
  align-items: flex-start;
  gap: 18px;
  min-width: 411px;
  height: 15px;
  margin-right: -2px;
  display: flex;
}

.number-4 {
  letter-spacing: 0;
  text-align: right;
  min-width: 16px;
  min-height: 15px;
  margin-top: -1.08px;
  line-height: normal;
}

.line-1 {
  align-self: center;
  width: 374px;
  height: 1px;
  margin-top: 1.09px;
}

.ai-product {
  background-color: var(--white);
  border: 1px;
  flex-direction: column;
  align-items: center;
  width: 1440px;
  height: 1440px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.overlap-group6 {
  width: 1168px;
  height: 250px;
  margin-top: 113px;
  position: relative;
}

.frame-97-1 {
  background: linear-gradient(#4282ff 0%, #4a94e7 56.77%, #3888e1 100%);
  align-items: flex-end;
  width: 1168px;
  height: 250px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}

.group-1000006558 {
  width: 1507px;
  height: 252px;
  margin-bottom: -2px;
  margin-left: -26px;
}

.this-revolutionary-g {
  letter-spacing: 0;
  text-align: center;
  width: 651px;
  line-height: normal;
  position: absolute;
  top: 158px;
  left: 248px;
}

.group-1000006557 {
  backdrop-filter: blur(4px) brightness();
  background-color: #fff3;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 267px;
  height: 74px;
  padding: 23.3px 34.3px;
  display: flex;
  position: absolute;
  top: 42px;
  left: 446px;
}

.ai-product-1 {
  letter-spacing: 0;
  text-align: center;
  width: 198px;
  min-height: 22px;
  line-height: normal;
}

.overlap-group7-1 {
  width: 1168px;
  height: 563px;
  position: relative;
}

.group-1000006439 {
  justify-content: flex-end;
  align-items: center;
  gap: 46px;
  min-width: 1168px;
  height: 563px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.line-2-1 {
  align-self: flex-end;
  width: 1px;
  height: 562px;
}

.group-1000006406-1 {
  height: 400px;
  margin-bottom: 19.04px;
}

input {
  border-style: hidden hidden groove;
  border-color: var(--blueberry);
}

.email-input:focus {
  outline: none;
}

.weve-trained-a-mode-1 {
  letter-spacing: 0;
  text-align: center;
  width: 417px;
  position: absolute;
  top: 246px;
  left: 79px;
}

.overlap-group5-1 {
  width: 1546px;
  height: 750px;
  margin-top: 117px;
  margin-left: 8px;
  display: none;
  position: relative;
}

.rectangle-176-1 {
  background: linear-gradient(#6273ff 0%, #8d96ddcc 100%);
  width: 1457px;
  height: 537px;
  position: absolute;
  top: 50px;
  left: 32px;
}

.ellipse-81-1 {
  border-radius: 269.5px / 275.5px;
  width: 539px;
  height: 551px;
  position: absolute;
  top: 107px;
  left: 0;
}

.line-785-1 {
  width: 1px;
  height: 539px;
  position: absolute;
  top: 155px;
  left: 421px;
}

.line-786-1 {
  width: 539px;
  height: 1px;
  position: absolute;
  top: 383px;
  left: 0;
}

.rectangle-179-1 {
  width: 484px;
  height: 462px;
  position: absolute;
  top: 152px;
  left: 55px;
}

.line-787-1 {
  width: 1011px;
  height: 1px;
  position: absolute;
  top: 153px;
  left: 535px;
}

.line-788-1 {
  width: 1px;
  height: 131px;
  position: absolute;
  top: 24px;
  left: 539px;
}

.ellipse-82-1 {
  background-color: var(--white);
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 377px;
  left: 415px;
}

.ellipse-83-1 {
  background-color: var(--white);
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 146px;
  left: 533px;
}

.ellipse-86-1 {
  background-color: var(--white);
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 147px;
  left: 1383px;
}

.ellipse-87-1 {
  background-color: var(--white);
  border-radius: 5px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 327px;
  left: 1172px;
}

.line-789-1 {
  width: 4px;
  height: 750px;
  position: absolute;
  top: 0;
  left: 1388px;
}

.ellipse-84-1 {
  border-radius: 189.92px;
  width: 380px;
  height: 380px;
  position: absolute;
  top: 34px;
  left: 1142px;
}

.ellipse-85-1 {
  border-radius: 120.2px;
  width: 240px;
  height: 240px;
  position: absolute;
  top: 269px;
  left: 986px;
}

.line-790-1 {
  width: 599px;
  height: 499px;
  position: absolute;
  top: 37px;
  left: 930px;
}

.overlap-group4 {
  background-color: var(--white);
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  width: 637px;
  min-height: 298px;
  padding: 52px 55px;
  display: flex;
  position: absolute;
  top: 396px;
  left: 451px;
  box-shadow: 0 4px 25px -6px #1033684a;
}

.title-1 {
  -webkit-text-fill-color: transparent;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(#8391ff 0%, #6b9efffc 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 345px;
  min-height: 48px;
  line-height: normal;
  -webkit-background-clip: text !important;
}

.group-1000006588-1 {
  align-items: flex-start;
  gap: 24px;
  min-width: 463px;
  height: 132px;
  display: flex;
}

.flex-col-1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 31px;
  width: 305px;
  min-height: 133px;
  margin-top: -1px;
  display: flex;
}

.join-the-waitlist-1 {
  letter-spacing: 0;
  width: 209px;
  min-height: 65px;
  line-height: normal;
}

.enter-your-email-address-1 {
  letter-spacing: 0;
  width: 230px;
  min-height: 22px;
  margin-left: 0;
  line-height: normal;
}

.overlap-group-9 {
  background-color: var(--blueberry);
  align-self: flex-end;
  align-items: flex-end;
  min-width: 130px;
  height: 54px;
  padding: 15px 31px;
  display: flex;
}

.submit-1 {
  letter-spacing: 0;
  text-align: center;
  width: 68px;
  min-height: 22px;
  line-height: normal;
}

.circle {
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 15%;
  height: 22%;
  transition: background-color .3s;
  display: flex;
  position: absolute;
}

.circle:hover {
  background-color: #0003;
}

.message {
  color: #000;
  text-align: center;
  font-weight: bold;
}

.circle-2, .circle-3 {
  position: absolute;
}

.leeds-calculator {
  background-color: #f3e5d5;
  border: 1px;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 3700px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.overlap-group20 {
  background-color: #f3e5d5;
  align-items: flex-start;
  min-width: 1170px;
  margin-top: 113px;
  padding: 0 1px;
  display: flex;
}

.frame-97 {
  background: url("Green Gradient Background 2.629c53d4.jpg");
  border-radius: 30px;
  align-items: flex-end;
  width: 1168px;
  height: 250px;
  display: flex;
  overflow: hidden;
}

.over-container {
  background-size: 100% 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  width: 1507px;
  min-height: 252px;
  margin-bottom: -2px;
  margin-left: -26px;
  padding: 42px 68px;
  display: flex;
}

.overlap-group1 {
  background-color: #ffffff3d;
  align-self: center;
  align-items: flex-start;
  min-width: 267px;
  height: 74px;
  padding: 22.4px 34.6px;
  display: flex;
}

.leed-calculator {
  letter-spacing: 0;
  text-align: center;
  width: 198px;
  min-height: 22px;
  line-height: normal;
}

input {
  border-style: hidden hidden groove;
  border-color: var(--blueberry);
}

.email-input:focus {
  outline: none;
}

.discover-the-power-o {
  letter-spacing: 0;
  text-align: center;
  width: 700px;
  min-height: 48px;
  margin-left: 180px;
  line-height: normal;
}

.overlap-group21 {
  align-self: flex-start;
  width: 1462px;
  height: 890px;
  margin-top: 111px;
  margin-bottom: 15%;
  position: relative;
}

.group-container {
  width: 330px;
  height: 330px;
  position: relative;
}

.group-1000006383 {
  background-size: 100% 100%;
  justify-content: flex-end;
  align-items: flex-start;
  min-width: 330px;
  height: 330px;
  padding: 0;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.subtract-container {
  width: 172px;
  height: 323px;
  position: relative;
}

.subtract {
  width: 133px;
  height: 166px;
  position: absolute;
  top: 5px;
  left: 39px;
}

.subtract-1 {
  width: 125px;
  height: 154px;
  position: absolute;
  top: 169px;
  left: 47px;
}

.subtract-2 {
  width: 45px;
  height: 33px;
  position: absolute;
  top: 0;
  left: 0;
}

.overlap-group1-1 {
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 140px;
  min-height: 93px;
  padding: 13.9px 22.7px;
  display: flex;
  position: absolute;
  top: 118px;
  left: 98px;
}

.lead-rating {
  letter-spacing: 0;
  text-align: center;
  min-width: 94px;
  min-height: 24px;
  margin-top: 9px;
  line-height: normal;
}

.text-1 {
  letter-spacing: 0;
  text-align: center;
  align-self: center;
  min-width: 32px;
  min-height: 24px;
  margin-left: 1.23px;
  line-height: normal;
}

.overlap-group23 {
  background-color: var(--white);
  justify-content: flex-end;
  align-self: flex-end;
  align-items: flex-end;
  min-width: 492px;
  height: 314px;
  margin-bottom: 5px;
  padding: 55px 36px;
  display: flex;
}

.lorem-ipsum-dolor-si {
  letter-spacing: 0;
  width: 401px;
  min-height: 200px;
  line-height: normal;
}

.overlap-group22 {
  width: 1168px;
  height: 258px;
  margin-top: 68px;
  margin-right: 2px;
  position: relative;
}

.leed-start-now {
  text-align: center;
  height: 10%;
  margin-right: 10%;
  font-size: 50px;
}

.start-now {
  text-align: center;
  margin-top: 2%;
  font-size: 50px;
}

.rectangle-184 {
  width: 574px;
  height: 258px;
  position: absolute;
  top: 0;
  left: 594px;
}

.rectangle-183 {
  background-color: #2566ffe3;
  width: 595px;
  height: 258px;
  position: absolute;
  top: 0;
  left: 0;
}

.frame-99 {
  flex-direction: column;
  align-items: flex-start;
  gap: 21px;
  width: 453px;
  min-height: 170px;
  display: flex;
  position: absolute;
  top: 44px;
  left: 74px;
}

.title {
  letter-spacing: 0;
  min-height: 49px;
  margin-top: -1px;
  line-height: normal;
}

.lorem-ipsum-dolor-si-1 {
  letter-spacing: 0;
  width: 453px;
  min-height: 100px;
  line-height: normal;
  font-size: 16px !important;
}

.foundersgrotesk-regular-normal-white-20-9px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-style: normal;
  font-weight: 400;
  font-size: var(--font-size-xl) !important;
}

.overlap-group19 {
  align-self: flex-start;
  width: 1546px;
  height: 750px;
  margin-top: 99px;
  margin-left: -34px;
  position: relative;
}

.rectangle-176 {
  background: linear-gradient(#6273ff 0%, #8d96ddcc 100%);
  width: 1457px;
  height: 537px;
  position: absolute;
  top: 50px;
  left: 32px;
}

.ellipse-81 {
  border-radius: 269.5px / 275.5px;
  width: 539px;
  height: 551px;
  position: absolute;
  top: 107px;
  left: 0;
}

.line-785 {
  width: 1px;
  height: 539px;
  position: absolute;
  top: 155px;
  left: 421px;
}

.line-786 {
  width: 539px;
  height: 1px;
  position: absolute;
  top: 383px;
  left: 0;
}

.rectangle-179 {
  width: 484px;
  height: 462px;
  position: absolute;
  top: 152px;
  left: 55px;
}

.line-787 {
  width: 1011px;
  height: 1px;
  position: absolute;
  top: 153px;
  left: 535px;
}

.line-788 {
  width: 1px;
  height: 131px;
  position: absolute;
  top: 24px;
  left: 539px;
}

.ellipse-82 {
  background-color: var(--white);
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 377px;
  left: 415px;
}

.ellipse-83 {
  background-color: var(--white);
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 146px;
  left: 533px;
}

.ellipse-86 {
  background-color: var(--white);
  border-radius: 6px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 147px;
  left: 1383px;
}

.ellipse-87 {
  background-color: var(--white);
  border-radius: 5px;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 327px;
  left: 1172px;
}

.line-789 {
  width: 4px;
  height: 750px;
  position: absolute;
  top: 0;
  left: 1388px;
}

.ellipse-84 {
  border-radius: 189.92px;
  width: 380px;
  height: 380px;
  position: absolute;
  top: 34px;
  left: 1142px;
}

.ellipse-85 {
  border-radius: 120.2px;
  width: 240px;
  height: 240px;
  position: absolute;
  top: 269px;
  left: 986px;
}

.line-790 {
  width: 599px;
  height: 499px;
  position: absolute;
  top: 37px;
  left: 930px;
}

.overlap-group17 {
  background-color: var(--white);
  flex-direction: column;
  align-items: flex-start;
  gap: 1px;
  width: 637px;
  min-height: 298px;
  padding: 52px 55px;
  display: flex;
  position: absolute;
  top: 396px;
  left: 451px;
  box-shadow: 0 4px 25px -6px #1033684a;
}

.want-to-know-more {
  -webkit-text-fill-color: transparent;
  letter-spacing: 0;
  text-fill-color: transparent;
  background: linear-gradient(#8391ff 0%, #6b9efffc 100%);
  -webkit-background-clip: text;
  background-clip: text;
  width: 345px;
  min-height: 48px;
  line-height: normal;
  -webkit-background-clip: text !important;
}

.group-1000006588 {
  align-items: flex-start;
  gap: 24px;
  min-width: 463px;
  height: 132px;
  display: flex;
}

.flex-col {
  flex-direction: column;
  align-items: flex-start;
  gap: 31px;
  width: 305px;
  min-height: 133px;
  margin-top: -1px;
  display: flex;
}

.join-the-waitlist {
  letter-spacing: 0;
  width: 209px;
  min-height: 65px;
  line-height: normal;
}

.enter-your-email-address {
  letter-spacing: 0;
  width: 230px;
  min-height: 22px;
  margin-left: 0;
  line-height: normal;
}

.overlap-group-2 {
  background-color: var(--blueberry);
  align-self: flex-end;
  align-items: flex-end;
  min-width: 130px;
  height: 54px;
  padding: 15px 31px;
  display: flex;
}

.submit {
  letter-spacing: 0;
  text-align: center;
  width: 68px;
  min-height: 22px;
  line-height: normal;
}

.frame-61 {
  backdrop-filter: blur(15.99px) brightness();
  background: linear-gradient(#2c6bff 0%, #3770f8e0 74.67%);
  align-items: flex-start;
  width: 1440px;
  height: 380px;
  margin-top: 92px;
  display: flex;
  overflow: hidden;
}

.line-740 {
  width: 293px;
  height: 1px;
  margin-top: 43px;
}

.group-1000006571 {
  width: 152px;
  height: 295px;
  margin-top: -4px;
}

.overlap-group-container-1 {
  align-self: flex-end;
  width: 658px;
  height: 342px;
  margin-bottom: -.5px;
  position: relative;
}

.overlap-group2-1 {
  width: 467px;
  height: 342px;
  position: absolute;
  top: 0;
  left: 191px;
}

.group-container-3 {
  width: 110px;
  height: 82px;
  position: absolute;
  top: 43px;
  left: 27px;
}

.overlap-group-5 {
  width: 110px;
  height: 82px;
  position: absolute;
  top: 0;
  left: 0;
}

.vector-24-2 {
  width: 13px;
  height: 11px;
  position: absolute;
  top: 0;
  left: 69px;
}

.rectangle-95-2 {
  background-color: var(--white);
  width: 54px;
  height: 71px;
  position: absolute;
  top: 0;
  left: 16px;
}

.vector-25-2 {
  width: 16px;
  height: 16px;
  position: absolute;
  top: 23px;
  left: 93px;
}

.vector-23-2 {
  width: 19px;
  height: 17px;
  position: absolute;
  top: 31px;
  left: 0;
}

.rectangle-96-2 {
  background-color: var(--white);
  width: 55px;
  height: 73px;
  position: absolute;
  top: 9px;
  left: 40px;
}

.group-1000006420-2 {
  width: 96px;
  height: 77px;
  position: absolute;
  top: 3px;
  left: 7px;
}

.rectangle-162 {
  border: 1px solid #fff;
  width: 164px;
  height: 165px;
  position: absolute;
  top: 0;
  left: 0;
}

.vector-36 {
  width: 304px;
  height: 262px;
  position: absolute;
  top: 79px;
  left: 164px;
}

.overlap-group-6 {
  background: linear-gradient(#ffffff21 0%, #ffffff1f 100%);
  border: 1px solid #fff;
  justify-content: flex-end;
  align-items: flex-end;
  min-width: 558px;
  height: 104px;
  padding: 20px 24px;
  display: flex;
  position: absolute;
  top: 199px;
  left: 0;
}

.an-ai-solution-to-ma {
  letter-spacing: 0;
  width: 501px;
  min-height: 54px;
}

.frame-61.frame-61-1 {
  margin-top: 62px;
}

.frame-100 {
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 197px;
  min-height: 108px;
  display: flex;
  position: absolute;
  top: 75px;
  left: 657px;
}

.reduce-peak-demand {
  letter-spacing: 0;
  min-height: 23px;
  margin-top: -1px;
  line-height: normal;
}

.ting {
  letter-spacing: 0;
  min-height: 23px;
  line-height: normal;
}

.frame-100.frame-101 {
  left: 908px;
}

.group-1000006440 {
  align-items: flex-start;
  min-width: 1172px;
  margin-left: 2px;
  display: flex;
}

.overlap-group3 {
  align-items: center;
  min-width: 1170px;
  height: 563px;
  padding: 0 87px;
  display: flex;
}

.weve-trained-a-mode {
  letter-spacing: 0;
  text-align: center;
  float: right;
  width: 417px;
  min-height: 368px;
  margin-top: 350px;
  margin-bottom: 19px;
  margin-right: 70px;
}

.line-2 {
  align-self: flex-end;
  width: 1px;
  height: 562px;
  margin-left: 82px;
}

.group-1000006406 {
  width: 351px;
  height: 370px;
  margin-bottom: 33.04px;
  margin-left: 137px;
}

.group-1000006470, .group-1000006470-1 {
  align-items: flex-start;
  width: 100%;
  margin-left: 4px;
  display: flex;
}

.overlap-group14, .overlap-group2 {
  backdrop-filter: blur(12px) brightness();
  background: linear-gradient(#c9c9c91a 0%, #77777703 100%);
  align-items: center;
  width: 100%;
  height: 114px;
  padding: 35px 50px;
  display: flex;
}

.group-container-1, .group-container-2 {
  align-self: flex-start;
  width: 58px;
  height: 43px;
  position: relative;
}

.overlap-group-3, .overlap-group-4 {
  width: 58px;
  height: 43px;
  position: absolute;
  top: 0;
  left: 0;
}

.green-logo {
  width: 10%;
  height: 10%;
}

.screen a {
  text-align: left;
  color: var(--ship-gray);
  text-decoration: none;
  display: contents;
}

.group-1000006420, .group-1000006420-1 {
  width: 75px;
  height: 60px;
  position: absolute;
  top: 1px;
  left: 4px;
}

.company, .company-1 {
  letter-spacing: 0;
  text-align: right;
  text-shadow: -.5px -.5px #fff, .5px -.5px #fff, -.5px .5px #fff, .5px .5px #fff;
  width: 100%;
  min-width: 71px;
  min-height: 22px;
  margin-top: 6px;
  line-height: normal;
}

.group-1000006484, .group-1000006484-1 {
  text-shadow: -.5px -.5px #fff, .5px -.5px #fff, -.5px .5px #fff, .5px .5px #fff;
  align-items: flex-start;
  gap: 8px;
  min-width: 77px;
  height: 22px;
  margin-top: 8px;
  margin-right: 10px;
  display: flex;
}

.product, .product-1 {
  letter-spacing: 0;
  text-align: center;
  text-shadow: -.5px -.5px #fff, .5px -.5px #fff, -.5px .5px #fff, .5px .5px #fff;
  min-width: 123px;
  min-height: 22px;
  margin-top: -1px;
  line-height: normal;
}

.product:hover, .product-1:hover {
  letter-spacing: 0;
  text-align: center;
  cursor: pointer;
  min-width: 123px;
  min-height: 22px;
  margin-top: -1px;
  line-height: normal;
}

.vector-34, .vector-34-1 {
  align-self: center;
  width: 9px;
  height: 5px;
  margin-bottom: 5px;
}

.place, .place-1 {
  letter-spacing: 0;
  text-align: center;
  text-shadow: -.5px -.5px #fff, .5px -.5px #fff, -.5px .5px #fff, .5px .5px #fff;
  min-width: 69px;
  min-height: 22px;
  margin-top: 6px;
  margin-left: 28px;
  line-height: normal;
}

.frame-11, .frame-11-1 {
  border: 1.5px solid #fff;
  align-items: flex-start;
  width: 117px;
  height: 42px;
  margin-left: 28px;
  padding: 12.6px 31px;
}

.sign-up, .sign-up-1 {
  letter-spacing: 0;
  text-align: center;
  text-shadow: -.5px -.5px #fff, .5px -.5px #fff, -.5px .5px #fff, .5px .5px #fff;
  width: 55px;
  min-height: 14px;
  line-height: normal;
}



:root {
  --anakiwa: #90ffff;
  --black: #000;
  --blue: #246dff;
  --blue-2: #184bffb2;
  --blue-chalk: #eae9ff;
  --blue-marguerite: #6b64f5;
  --blueberry: #5891ff;
  --blueberry-2: #534fff;
  --blueberry-3: #7484ff;
  --blueberry-4: #5c6effb2;
  --cherokee: #ffd79cb2;
  --desert-storm: #f8f8f8d9;
  --fuscous-gray: #525252;
  --melrose: #c4bfffb2;
  --melrose-2: #b0c6ff99;
  --persian-blue: #1c34f2;
  --pigeon-post: #abbddf;
  --portage: #8290ff;
  --portage-2: #8290ff1c;
  --shark: #282828;
  --ship-gray: #383b46;
  --silver: #bebebe;
  --star-dust: #9c9c9c;
  --turquoise-blue: #3cdeff;
  --white: #fff;
  --white-2: #fffc;
  --font-size-l: 20px;
  --font-size-m: 19.5px;
  --font-size-s: 19px;
  --font-size-xl: 20.4px;
  --font-size-xxl: 20.9px;
  --font-size-xxxl: 21.8px;
  --font-size-xxxxl: 22px;
  --font-family-f25_bank_printer-regular: "F25 Bank Printer-Regular", Helvetica;
  --font-family-founders_grotesk-light: "Founders Grotesk-Light", Helvetica;
  --font-family-founders_grotesk-medium: "Founders Grotesk-Medium", Helvetica;
  --font-family-founders_grotesk-regular: "Founders Grotesk-Regular", Helvetica;
  --font-family-sf_pro_display-regular: "SF Pro Display-Regular", Helvetica;
}

.foundersgrotesk-regular-normal-white-21-8px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-medium-white-21-8px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-medium);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 500;
}

.foundersgrotesk-regular-normal-white-20px, .foundersgrotesk-regular-normal-white-20px-2 {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-ship-gray-18px {
  color: var(--ship-gray);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
}

.sfprodisplay-regular-normal-star-dust-12-9px {
  color: var(--star-dust);
  font-family: var(--font-family-sf_pro_display-regular);
  font-size: 12.9px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-blue-marguerite-18-4px {
  color: var(--blue-marguerite);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 18.4px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-fuscous-gray-19-5px {
  color: var(--fuscous-gray);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.sfprodisplay-regular-normal-silver-12-9px {
  color: var(--silver);
  font-family: var(--font-family-sf_pro_display-regular);
  font-size: 12.9px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-persian-blue-19px {
  color: var(--persian-blue);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-medium-persian-blue-19px {
  color: var(--persian-blue);
  font-family: var(--font-family-founders_grotesk-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.foundersgrotesk-regular-normal-white-30px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-black-19-5px {
  color: var(--black);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-pigeon-post-20px {
  color: var(--pigeon-post);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-ship-gray-20px, .foundersgrotesk-regular-normal-ship-gray-20px-2 {
  color: var(--ship-gray);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-turquoise-blue-41px {
  color: var(--turquoise-blue);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 41px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-white-26px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-white-22px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-white-22-1px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 22.1px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-ship-gray-71px {
  color: var(--ship-gray);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 71px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-blueberry-35px {
  color: var(--blueberry-2);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-white-24px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-light-black-20-2px {
  color: var(--black);
  font-family: var(--font-family-founders_grotesk-light);
  font-size: 20.2px;
  font-style: normal;
  font-weight: 300;
}

.foundersgrotesk-regular-normal-black-20-2px {
  color: var(--black);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 20.2px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-shark-20-9px {
  color: var(--shark);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-anakiwa-40-9px {
  color: var(--anakiwa);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 40.9px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-white-20-9px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-white-38px {
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-black-38px {
  color: var(--black);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-blueberry-27px {
  color: var(--blueberry-3);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-portage-27px {
  color: var(--portage);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-blueberry-27px-2 {
  color: var(--blueberry);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-light-ship-gray-23-4px {
  color: var(--ship-gray);
  font-family: var(--font-family-founders_grotesk-light);
  font-size: 23.4px;
  font-style: normal;
  font-weight: 300;
}

.foundersgrotesk-regular-normal-white-16-2px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 16.2px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-ship-gray-16-2px {
  color: var(--ship-gray);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 16.2px;
  font-style: normal;
  font-weight: 400;
}

.f25bankprinter-regular-normal-blueberry-10px {
  color: var(--blueberry-4);
  font-family: var(--font-family-f25_bank_printer-regular);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-white-39-4px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 39.4px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-white-20-4px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-white-29-4px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 29.4px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-desert-storm-20px {
  color: var(--desert-storm);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

/*# sourceMappingURL=index.5fd35133.css.map */
