/* Group 1000006617 */
.superior {
    max-width: 1440px;
    height: 1435px;
    border: 1px;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    position: relative;
    overflow: hidden;
}


a:any-link{
    text-decoration: none;
}
.overlap-group17 {
    background-color: var(--white);
    min-height: 298px;
    width: 637px;
    flex-direction: column;
    align-items: flex-start;
    display: flex;
    position: fixed;
    top: 396px;
    left: 451px;
    box-shadow: rgba(16, 51, 104, 0.29) 0px 4px 25px -6px;
    gap: 1px;
    padding: 52px 55px;

}
.frame-61{
    align-items: flex-start;
    margin-left: 30px;
    display: flex;
    overflow: hidden;
}



.curry{
    color: var(--blueberry) !important;
}

.submit{
    margin-top: auto;
    text-decoration: none;
    background-color: var(--blueberry);
    border: none;
}
.overlap-group19 {
    align-self: flex-start;
    height: 750px;
    width: 1546px;
    position: fixed ;
    max-width: 500px;
    margin: 0 auto;
}
.rectangle-176 {
    background: linear-gradient(180deg, rgb(97.75, 115.44, 255) 0%, rgba(141, 150, 221, 0.8) 100%);
    height: 537px;
    left: 32px;
    position: absolute;
    top: 50px;
    width: 1457px;

}
.ellipse-81 {
    border-radius: 269.5px/275.5px;
    height: 551px;
    left: 0;
    position: absolute;
    top: 107px;
    width: 539px;
}
.ellipse-81 {
    border-radius: 269.5px/275.5px;
    height: 551px;
    left: 0;
    position: absolute;
    top: 107px;
    width: 539px;
}

.line-785 {
    height: 539px;
    left: 421px;
    position: absolute;
    top: 155px;
    width: 1px;
}

.line-786 {
    height: 1px;
    left: 0;
    position: absolute;
    top: 383px;
    width: 539px;
}

.rectangle-179 {
    height: 462px;
    left: 55px;
    position: absolute;
    top: 152px;
    width: 484px;
}

.line-787 {
    height: 1px;
    left: 535px;
    position: absolute;
    top: 153px;
    width: 1011px;
}

.line-788 {
    height: 131px;
    left: 539px;
    position: absolute;
    top: 24px;
    width: 1px;
}

.ellipse-82 {
    background-color: var(--white);
    border-radius: 6px;
    height: 12px;
    left: 415px;
    position: absolute;
    top: 377px;
    width: 12px;
}

.ellipse-83 {
    background-color: var(--white);
    border-radius: 6px;
    height: 12px;
    left: 533px;
    position: absolute;
    top: 146px;
    width: 12px;
}

.ellipse-86 {
    background-color: var(--white);
    border-radius: 6px;
    height: 12px;
    left: 1383px;
    position: absolute;
    top: 147px;
    width: 12px;
}

.ellipse-87 {
    background-color: var(--white);
    border-radius: 5px;
    height: 10px;
    left: 1172px;
    position: absolute;
    top: 327px;
    width: 10px;
}

.line-789 {
    height: 750px;
    left: 1388px;
    position: absolute;
    top: 0;
    width: 4px;
}

.ellipse-84 {
    border-radius: 189.92px;
    height: 380px;
    left: 1142px;
    position: absolute;
    top: 34px;
    width: 380px;
}

.ellipse-85 {
    border-radius: 120.2px;
    height: 240px;
    left: 986px;
    position: absolute;
    top: 269px;
    width: 240px;
}

.line-790 {
    height: 499px;
    left: 930px;
    position: absolute;
    top: 37px;
    width: 599px;
}
.overlap-group17 {
    align-items: flex-start;
    background-color: var(--white);
    box-shadow: 0px 4px 25px -6px #1033684a;
    display: flex;
    flex-direction: column;
    gap: 1px;
    left: 451px;
    min-height: 298px;
    padding: 52px 55px;
    position: absolute;
    top: 396px;
    width: 637px;
}

.want-to-know-more {
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent;
    background: linear-gradient(180deg, rgb(130.69, 144.5, 255) 0%, rgba(107.31, 157.53, 255, 0.99) 100%);
    background-clip: text;
    letter-spacing: 0;
    line-height: normal;
    min-height: 48px;
    text-fill-color: transparent;
    width: 345px;
}

.group-1000006588 {
    align-items: flex-start;
    display: flex;
    gap: 24px;
    height: 132px;
    min-width: 463px;
}

.flex-col {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    gap: 31px;
    margin-top: -1px;
    min-height: 133px;
    width: 305px;
}

.join-the-waitlist {
    letter-spacing: 0;
    line-height: normal;
    min-height: 65px;
    width: 209px;
}

.enter-your-email-address {
    letter-spacing: 0;
    line-height: normal;
    margin-left: 17px;
    min-height: 22px;
    width: 230px;
}

.overlap-group-2 {
    align-items: flex-end;
    align-self: flex-end;
    background-color: var(--blueberry);
    display: flex;
    height: 54px;
    min-width: 130px;
    padding: 15px 31px;
}

.submit {
    letter-spacing: 0;
    line-height: normal;
    min-height: 22px;
    text-align: center;
    width: 68px;
}