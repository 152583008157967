.ai-product {
  align-items: center;
  background-color: var(--white);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 1440px;
  overflow: hidden;
  position: relative;
  width: 1440px;
}

.overlap-group6 {
  height: 250px;
  margin-top: 113px;
  position: relative;
  width: 1168px;
}

.frame-97-1 {
  align-items: flex-end;
  background: linear-gradient(180deg, rgb(65.87, 130.13, 255) 0%, rgb(74, 148, 231) 56.77%, rgb(56.31, 135.94, 225.25) 100%);
  display: flex;
  height: 250px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 1168px;
}

.group-1000006558 {
  height: 252px;
  margin-bottom: -2.0px;
  margin-left: -26px;
  width: 1507px;
}

.this-revolutionary-g {
  left: 248px;
  letter-spacing: 0;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: 158px;
  width: 651px;
}

.group-1000006557 {
  -webkit-backdrop-filter: blur(4px) brightness(100%);
  align-items: flex-start;
  backdrop-filter: blur(4px) brightness(100%);
  background-color: #ffffff33;
  display: flex;
  height: 74px;
  justify-content: flex-end;
  left: 446px;
  min-width: 267px;
  padding: 23.3px 34.3px;
  position: absolute;
  top: 42px;
}

.ai-product-1 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 22px;
  text-align: center;
  width: 198px;
}

.overlap-group7-1 {
  height: 563px;
  position: relative;
  width: 1168px;
}

.group-1000006439 {
  align-items: center;
  display: flex;
  gap: 46px;
  height: 563px;
  justify-content: flex-end;
  left: 0;
  min-width: 1168px;
  /*padding: 0 95.3px;*/
  position: absolute;
  top: 0;
}

.line-2-1 {
  align-self: flex-end;
  height: 562px;
  width: 1px;
}

.group-1000006406-1 {
  height: 400px;
  margin-bottom: 19.04px;
  /*width: 350px;*/
}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  border-color: var(--blueberry);
}
.email-input:focus {
  outline: none;
}

.weve-trained-a-mode-1 {
  left: 79px;
  letter-spacing: 0;
  /* line-height: 106.2px; */
  position: absolute;
  text-align: center;
  top: 246px;
  width: 417px;
}

.overlap-group5-1 {
  height: 750px;
  margin-left: 8.0px;
  margin-top: 117px;
  position: relative;
  width: 1546px;
  display:none;
}

.rectangle-176-1 {
  background: linear-gradient(180deg, rgb(97.75, 115.44, 255) 0%, rgba(141, 150, 221, 0.8) 100%);
  height: 537px;
  left: 32px;
  position: absolute;
  top: 50px;
  width: 1457px;
}

.ellipse-81-1 {
  border-radius: 269.5px/275.5px;
  height: 551px;
  left: 0;
  position: absolute;
  top: 107px;
  width: 539px;
}

.line-785-1 {
  height: 539px;
  left: 421px;
  position: absolute;
  top: 155px;
  width: 1px;
}

.line-786-1 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 383px;
  width: 539px;
}

.rectangle-179-1 {
  height: 462px;
  left: 55px;
  position: absolute;
  top: 152px;
  width: 484px;
}

.line-787-1 {
  height: 1px;
  left: 535px;
  position: absolute;
  top: 153px;
  width: 1011px;
}

.line-788-1 {
  height: 131px;
  left: 539px;
  position: absolute;
  top: 24px;
  width: 1px;
}

.ellipse-82-1 {
  background-color: var(--white);
  border-radius: 6px;
  height: 12px;
  left: 415px;
  position: absolute;
  top: 377px;
  width: 12px;
}

.ellipse-83-1 {
  background-color: var(--white);
  border-radius: 6px;
  height: 12px;
  left: 533px;
  position: absolute;
  top: 146px;
  width: 12px;
}

.ellipse-86-1 {
  background-color: var(--white);
  border-radius: 6px;
  height: 12px;
  left: 1383px;
  position: absolute;
  top: 147px;
  width: 12px;
}

.ellipse-87-1 {
  background-color: var(--white);
  border-radius: 5px;
  height: 10px;
  left: 1172px;
  position: absolute;
  top: 327px;
  width: 10px;
}

.line-789-1 {
  height: 750px;
  left: 1388px;
  position: absolute;
  top: 0;
  width: 4px;
}

.ellipse-84-1 {
  border-radius: 189.92px;
  height: 380px;
  left: 1142px;
  position: absolute;
  top: 34px;
  width: 380px;
}

.ellipse-85-1 {
  border-radius: 120.2px;
  height: 240px;
  left: 986px;
  position: absolute;
  top: 269px;
  width: 240px;
}

.line-790-1 {
  height: 499px;
  left: 930px;
  position: absolute;
  top: 37px;
  width: 599px;
}

.overlap-group4 {
  align-items: flex-start;
  background-color: var(--white);
  box-shadow: 0px 4px 25px -6px #1033684a;
  display: flex;
  flex-direction: column;
  gap: 1px;
  left: 451px;
  min-height: 298px;
  padding: 52px 55px;
  position: absolute;
  top: 396px;
  width: 637px;
}

.title-1 {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(180deg, rgb(130.69, 144.5, 255) 0%, rgba(107.31, 157.53, 255, 0.99) 100%);
  background-clip: text;
  letter-spacing: 0;
  line-height: normal;
  min-height: 48px;
  text-fill-color: transparent;
  width: 345px;
}

.group-1000006588-1 {
  align-items: flex-start;
  display: flex;
  gap: 24px;
  height: 132px;
  min-width: 463px;
}

.flex-col-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 31px;
  margin-top: -1px;
  min-height: 133px;
  width: 305px;
}

.join-the-waitlist-1 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 65px;
  width: 209px;
}

.enter-your-email-address-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 0px;
  min-height: 22px;
  width: 230px;
}

.overlap-group-9 {
  align-items: flex-end;
  align-self: flex-end;
  background-color: var(--blueberry);
  display: flex;
  height: 54px;
  min-width: 130px;
  padding: 15px 31px;
}

.submit-1 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 22px;
  text-align: center;
  width: 68px;
}

