.group-1000006471 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  left: 67px;
  min-height: 231px;
  position: absolute;
  top: 162px;
  width: 457px;
}

.energy-consumption-calculator {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 31px;
}

.line-15 {
  height: 1px;
  margin-left: 1px;
  margin-top: 23px;
  width: 91px;
}

.construction-account {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 20px;
  min-height: 156px;
  width: 453px;
}

