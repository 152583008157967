:root { 
  --anakiwa: #90ffff;
  --black: #000000;
  --blue: #246dff;
  --blue-2: #184bffb2;
  --blue-chalk: #eae9ff;
  --blue-marguerite: #6b64f5;
  --blueberry: #5891ff;
  --blueberry-2: #534fff;
  --blueberry-3: #7484ff;
  --blueberry-4: #5c6effb2;
  --cherokee: #ffd79cb2;
  --desert-storm: #f8f8f8d9;
  --fuscous-gray: #525252;
  --melrose: #c4bfffb2;
  --melrose-2: #b0c6ff99;
  --persian-blue: #1c34f2;
  --pigeon-post: #abbddf;
  --portage: #8290ff;
  --portage-2: #8290ff1c;
  --shark: #282828;
  --ship-gray: #383b46;
  --silver: #bebebe;
  --star-dust: #9c9c9c;
  --turquoise-blue: #3cdeff;
  --white: #ffffff;
  --white-2: #ffffffcc;
 
  --font-size-l: 20px;
  --font-size-m: 19.5px;
  --font-size-s: 19px;
  --font-size-xl: 20.4px;
  --font-size-xxl: 20.9px;
  --font-size-xxxl: 21.8px;
  --font-size-xxxxl: 22px;
 
  --font-family-f25_bank_printer-regular: "F25 Bank Printer-Regular", Helvetica;
  --font-family-founders_grotesk-light: "Founders Grotesk-Light", Helvetica;
  --font-family-founders_grotesk-medium: "Founders Grotesk-Medium", Helvetica;
  --font-family-founders_grotesk-regular: "Founders Grotesk-Regular", Helvetica;
  --font-family-sf_pro_display-regular: "SF Pro Display-Regular", Helvetica;
}
.foundersgrotesk-regular-normal-white-21-8px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-medium-white-21-8px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-medium);
  font-size: var(--font-size-xxxl);
  font-style: normal;
  font-weight: 500;
}

.foundersgrotesk-regular-normal-white-20px,
.foundersgrotesk-regular-normal-white-20px-2 {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-ship-gray-18px {
  color: var(--ship-gray);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  text-decoration: none;
}

.sfprodisplay-regular-normal-star-dust-12-9px {
  color: var(--star-dust);
  font-family: var(--font-family-sf_pro_display-regular);
  font-size: 12.9px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-blue-marguerite-18-4px {
  color: var(--blue-marguerite);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 18.4px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-fuscous-gray-19-5px {
  color: var(--fuscous-gray);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.sfprodisplay-regular-normal-silver-12-9px {
  color: var(--silver);
  font-family: var(--font-family-sf_pro_display-regular);
  font-size: 12.9px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-persian-blue-19px {
  color: var(--persian-blue);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-medium-persian-blue-19px {
  color: var(--persian-blue);
  font-family: var(--font-family-founders_grotesk-medium);
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 500;
}

.foundersgrotesk-regular-normal-white-30px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-black-19-5px {
  color: var(--black);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-m);
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-pigeon-post-20px {
  color: var(--pigeon-post);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-ship-gray-20px,
.foundersgrotesk-regular-normal-ship-gray-20px-2 {
  color: var(--ship-gray);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-turquoise-blue-41px {
  color: var(--turquoise-blue);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 41px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-white-26px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-white-22px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-xxxxl);
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-white-22-1px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 22.1px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-ship-gray-71px {
  color: var(--ship-gray);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 71px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-blueberry-35px {
  color: var(--blueberry-2);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 35px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-white-24px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-light-black-20-2px {
  color: var(--black);
  font-family: var(--font-family-founders_grotesk-light);
  font-size: 20.2px;
  font-style: normal;
  font-weight: 300;
}

.foundersgrotesk-regular-normal-black-20-2px {
  color: var(--black);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 20.2px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-shark-20-9px {
  color: var(--shark);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-anakiwa-40-9px {
  color: var(--anakiwa);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 40.9px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-white-20-9px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-xxl);
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-white-38px {
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-black-38px {
  color: var(--black);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-blueberry-27px {
  color: var(--blueberry-3);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-portage-27px {
  color: var(--portage);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-blueberry-27px-2 {
  color: var(--blueberry);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 27px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-light-ship-gray-23-4px {
  color: var(--ship-gray);
  font-family: var(--font-family-founders_grotesk-light);
  font-size: 23.4px;
  font-style: normal;
  font-weight: 300;
}

.foundersgrotesk-regular-normal-white-16-2px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 16.2px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-ship-gray-16-2px {
  color: var(--ship-gray);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 16.2px;
  font-style: normal;
  font-weight: 400;
}

.f25bankprinter-regular-normal-blueberry-10px {
  color: var(--blueberry-4);
  font-family: var(--font-family-f25_bank_printer-regular);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-white-39-4px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 39.4px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-white-20-4px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-xl);
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-white-29-4px {
  color: var(--white);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: 29.4px;
  font-style: normal;
  font-weight: 400;
}

.foundersgrotesk-regular-normal-desert-storm-20px {
  color: var(--desert-storm);
  font-family: var(--font-family-founders_grotesk-regular);
  font-size: var(--font-size-l);
  font-style: normal;
  font-weight: 400;
}
