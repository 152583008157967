.frame-61-2 {
  -webkit-backdrop-filter: blur(15.99px) brightness(100%);
  align-items: flex-start;
  backdrop-filter: blur(15.99px) brightness(100%);
  background: linear-gradient(180deg, rgb(37.19, 102.04, 255) 0%, rgba(37.19, 102.04, 255, 0.88) 74.67%);
  display: flex;
  height: 380px;
  margin-top: 123px;
  overflow: hidden;
  width: 1440px;
}
.link-class{
  color: white !important;
}

.group-container-27 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 336px;
  width: 749px;
}

.overlap-group2-4 {
  height: 119px;
  margin-left: 136px;
  position: relative;
  width: 119px;
}

.group-container-28 {
  height: 59px;
  left: 20px;
  position: absolute;
  top: 31px;
  width: 80px;
}

.overlap-group-15 {
  height: 59px;
  left: 0;
  position: absolute;
  top: 0;
  width: 80px;
}

.vector-24-5 {
  height: 8px;
  left: 50px;
  position: absolute;
  top: 0;
  width: 9px;
}

.rectangle-95-5 {
  height: 51px;
  left: 11px;
  position: absolute;
  top: 0;
  width: 39px;
}

.vector-25-5 {
  height: 11px;
  left: 68px;
  position: absolute;
  top: 16px;
  width: 12px;
}

.vector-23-5 {
  height: 13px;
  left: 0;
  position: absolute;
  top: 23px;
  width: 14px;
}

.rectangle-96-5 {
  background-color: var(--white);
  height: 53px;
  left: 29px;
  position: absolute;
  top: 6px;
  width: 40px;
}

.group-1000006420-5 {
  height: 55px;
  left: 5px;
  position: absolute;
  top: 2px;
  width: 70px;
}

.rectangle-162-2 {
  height: 119px;
  left: 0;
  position: absolute;
  top: 0;
  width: 119px;
}

.group-1000006570 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  min-height: 104px;
  width: 749px;
}

.overlap-group-16 {
  align-items: flex-end;
  align-self: flex-end;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0.12) 100%);
  display: flex;
  height: 104px;
  min-width: 613px;
  padding: 20px 33px;
}

.an-ai-solution-to-ma-3 {
  letter-spacing: 0;
  /* line-height: 135.5px; */
  min-height: 54px;
  /* white-space: nowrap; */
  width: 501px;
}

.line-739 {
  height: 1px;
  width: 138px;
}

.group-1000006403 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  gap: 184px;
  justify-content: flex-end;
  margin-left: 79.0px;
  margin-top: 47px;
  min-width: 556px;
}

.join-the-waitlist-2 {
  align-self: center;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 5.6px;
  min-height: 24px;
  width: 177px;
}

.overlap-group-17 {
  align-items: flex-end;
  background-color: var(--white);
  display: flex;
  height: 66px;
  justify-content: flex-end;
  min-width: 171px;
  padding: 18px 54px;
}

.sign-up-3 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 24px;
  min-width: 62px;
  text-align: center;
}

.line-19 {
  align-self: flex-end;
  height: 383px;
  margin-bottom: -3px;
  width: 1px;
}

.group-1000006567 {
  align-items: flex-start;
  align-self: center;
  display: flex;
  flex-direction: column;
  gap: 47px;
  margin-left: 80px;
  margin-top: 10.0px;
  min-height: 282px;
  width: 476px;
}

.group-1000006563 {
  align-items: flex-start;
  display: flex;
  min-width: 476px;
}

.overlap-group1-3 {
  height: 169px;
  position: relative;
  width: 221px;
}

.rectangle-66 {
  height: 169px;
  left: 0;
  position: absolute;
  top: 0;
  width: 181px;
}

.line-736 {
  height: 1px;
  left: 180px;
  position: absolute;
  top: 84px;
  width: 41px;
}

.frame-62 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 17px;
  left: 51px;
  min-height: 106px;
  position: absolute;
  top: 32px;
  width: 79px;
}

.company-3 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 24px;
  min-width: 79px;
  text-align: center;
}

.product-3 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 24px;
  min-width: 64px;
  text-align: center;
}



.place-3 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 24px;
  min-width: 77px;
  text-align: center;
}

.overlap-group-18 {
  align-items: center;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 100%);
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 169px;
  padding: 54.8px 0;
  width: 255px;
  border: .9999999px solid white;

}

.leed-calculator-2 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 16px;
  text-align: center;
  width: 159px;
}

.ai-product-2 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 16px;
  text-align: center;
  width: 94px;
}

.group-1000006564 {
  align-items: flex-end;
  display: flex;
  gap: 25px;
  height: 66px;
  min-width: 284px;
  padding: 16.2px 37px;
}

.terms {
  letter-spacing: 0;
  line-height: normal;
  min-height: 27px;
  min-width: 57px;
  text-align: center;
}

.privacy-policy {
  letter-spacing: 0;
  line-height: normal;
  min-height: 27px;
  min-width: 122px;
  text-align: center;
}

