.group-100000659-1 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 14px;
  left: 23px;
  min-height: 56px;
  position: absolute;
  top: 314px;
  width: 247px;
}

.name {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 4.0px;
  margin-top: -1px;
  min-height: 21px;
  text-align: center;
  width: 161px;
}

.developer {
  letter-spacing: 0;
  line-height: normal;
  margin-right: 4.0px;
  min-height: 21px;
  text-align: center;
  width: 243px;
}

.group-100000659-1.group-1000006593 {
  left: 21px;
}

