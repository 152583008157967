.group-1000006470,
.group-1000006470-1 {
  align-items: flex-start;
  display: flex;
  margin-left: 4.0px;
  width: 100%;
}

.overlap-group14,
.overlap-group2 {
  -webkit-backdrop-filter: blur(12px) brightness(100%);
  align-items: center;
  backdrop-filter: blur(12px) brightness(100%);
  background: linear-gradient(180deg, rgba(200.81, 200.81, 200.81, 0.1) 0%, rgba(119, 119, 119, 0.01) 100%);
  display: flex;
  height: 114px;
  width: 100%;
  padding: 35.0px 50px;
}

.group-container-1,
.group-container-2 {
  align-self: flex-start;
  height: 43px;
  position: relative;
  width: 58px;
}

.overlap-group-3,
.overlap-group-4 {
  height: 43px;
  left: 0;
  position: absolute;
  top: 0;
  width: 58px;
}

/* Logo styling */


.green-logo {
  width: 10%;
  height: 10%;
}

/* .vector-24,
.vector-24-1 {
  height: 6px;
  left: 37px;
  position: absolute;
  top: 0;
  width: 7px;
} */

/* .rectangle-95,
.rectangle-95-1 {
  height: 38px;
  left: 8px;
  position: absolute;
  top: 0;
  width: 29px;
} */

/* .vector-25,
.vector-25-1 {
  height: 8px;
  left: 50px;
  position: absolute;
  top: 12px;
  width: 9px;
}

.vector-23,
.vector-23-1 {
  height: 9px;
  left: 0;
  position: absolute;
  top: 17px;
  width: 10px;
} */

/* .rectangle-96,
.rectangle-96-1 {
  background-color: var(--blue);
  height: 39px;
  left: 21px;
  position: absolute;
  top: 5px;
  width: 29px;
} */

.screen a {
  text-decoration: none;
  display:contents;
  text-align: left;
  color: var(--ship-gray);
  
}

/* Green logo styling */
.group-1000006420,
.group-1000006420-1 {
  height: 60px;
  left: 4px;
  position: absolute;
  top: 1px;
  width: 75px;
}
/* company on navbar */
.company,
.company-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 6.0px;
  min-height: 22px;
  min-width: 71px;
  text-align: right;
  width: 100%;
  text-shadow: -.5px -.5px 0 white, .5px -.5px 0 white, -.5px .5px 0 white, .5px .5px 0 white;
}

/* this is the product on navbar*/

.group-1000006484,
.group-1000006484-1 {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  height: 22px;
  margin-top: 8.0px;
  min-width: 77px;
  margin-right: 10px;

  text-shadow: -.5px -.5px 0 white, .5px -.5px 0 white, -.5px .5px 0 white, .5px .5px 0 white;
}
/* not sure what this is */
.product,
.product-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 22px;
  min-width: 123px;
  text-align: center;

  text-shadow: -.5px -.5px 0 white, .5px -.5px 0 white, -.5px .5px 0 white, .5px .5px 0 white;
}

.product:hover,
.product-1:hover {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 22px;
  min-width: 123px;
  text-align: center;
  cursor: pointer;
}

.vector-34,
.vector-34-1 {
  align-self: center;
  height: 5px;
  margin-bottom: 5.0px;
  width: 9px;
}

/* I think this is research */
.place,
.place-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 28px;
  margin-top: 6.0px;
  min-height: 22px;
  min-width: 69px;
  text-align: center;

  text-shadow: -.5px -.5px 0 white, .5px -.5px 0 white, -.5px .5px 0 white, .5px .5px 0 white;
}

.frame-11,
.frame-11-1 {
  align-items: flex-start;
  /* display: flex; */
  height: 42px;
  margin-left: 28px;
  padding: 12.6px 31px;
  width: 117px;
  /* border: 1px solid var(--ship-gray) */
  border: 1.5px solid white;
}

.sign-up,
.sign-up-1 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 14px;
  text-align: center;
  width: 55px;

  text-shadow: -.5px -.5px 0 white, .5px -.5px 0 white, -.5px .5px 0 white, .5px .5px 0 white;
}

