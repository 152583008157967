@import url("https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.min.css");
/* The following line is used to measure usage of this code in production. For more info see our usage billing page */
@import url("https://px.animaapp.com/642cf3b4870454d79572a153.642cf3b50cd9f12f63dbb07e.s7AAvnh.rcp.png");

@font-face {
  font-family: "Founders Grotesk-Regular";
  font-style: normal;
  font-weight: 400;
  src: url("static/fonts/FoundersGrotesk-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Founders Grotesk-Light";
  font-style: normal;
  font-weight: 300;
  src: url("static/fonts/FoundersGrotesk-Light.otf") format("opentype");
}


.screen a {
  display: contents;
  text-decoration: none;
}

.container-center-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;
  width: 100%;
}

.container-center-horizontal > * {
  flex-shrink: 0;
  pointer-events: auto;
}

* {
  box-sizing: border-box;
}
