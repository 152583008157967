.frame-100 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 657px;
  min-height: 108px;
  position: absolute;
  top: 75px;
  width: 197px;
}

.reduce-peak-demand {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 23px;
}

.ting {
  letter-spacing: 0;
  line-height: normal;
  min-height: 23px;
}

.frame-100.frame-101 {
  left: 908px;
}

