.leeds-calculator {
  align-items: center;
  background-color: rgb(243, 229, 213);
  border: 1px none;
  display: flex;
  flex-direction: column;
  height: 3700px;
  overflow: hidden;
  position: relative;
  /* width: 1440px; */
  width: 100%;
}

.overlap-group20 {
  align-items: flex-start;
  background-color: rgb(243, 229, 213);
  display: flex;
  margin-top: 113px;
  min-width: 1170px;
  padding: 0 1px;
}

.frame-97 {
  align-items: flex-end;
  /* background: linear-gradient(180deg, rgb(65.87, 130.13, 255) 0%, rgb(74, 148, 231) 56.77%, rgb(56.31, 135.94, 225.25) 100%); */
  background: url('Green\ Gradient\ Background\ 2.jpg'); 
  display: flex;
  height: 250px;
  overflow: hidden;
  width: 1168px;
  border-radius: 30px;
}

.over-container {
  align-items: flex-start;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: -2.0px;
  margin-left: -26px;
  min-height: 252px;
  padding: 42px 68px;
  width: 1507px;
  
}

.overlap-group1 {
  align-items: flex-start;
  align-self: center;
  background-color: #ffffff3d;
  display: flex;
  height: 74px;
  /* margin-right: 288.0px; */
  min-width: 267px;
  padding: 22.4px 34.6px;
}

.leed-calculator {
  letter-spacing: 0;
  line-height: normal;
  min-height: 22px;
  text-align: center;
  width: 198px;
}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: groove;
  border-color: var(--blueberry);
}
.email-input:focus {
  outline: none;
}
.discover-the-power-o {
  letter-spacing: 0;
  line-height: normal;
  min-height: 48px;
  text-align: center;
  width: 700px;
  margin-left:180px;
}

.overlap-group21 {
  align-self: flex-start;
  height: 890px;
  /* change to adequate % later */
  margin-top: 111px;
  position: relative;
  width: 1462px;
  margin-bottom: 15%;
  /* keep for now, change to 100% later */
}

.overlap-group-container {
  /* Past leed rating styling */
  /* -webkit-backdrop-filter: blur(20.67px) brightness(100%);
  align-items: flex-start;
  backdrop-filter: blur(20.67px) brightness(100%);
  background: linear-gradient(180deg, rgba(183.81, 191.31, 255, 0.27) 0%, rgba(189.13, 196.06, 255, 0.19) 59.31%, rgba(235.88, 237.89, 255, 0.15) 100%);
  display: flex;
  gap: 204px;
  height: 439px;
  justify-content: flex-end;
  margin-top: 137px;
  min-width: 1170px;
  padding: 52px 57px; */

  
}

.group-container {
  height: 330px;
  position: relative;
  width: 330px;
}

.group-1000006383 {
  align-items: flex-start;
  background-size: 100% 100%;
  display: flex;
  height: 330px;
  justify-content: flex-end;
  left: 0;
  min-width: 330px;
  padding: 0 0.0px;
  position: absolute;
  top: 0;
}

.subtract-container {
  height: 323px;
  position: relative;
  width: 172px;
}

.subtract {
  height: 166px;
  left: 39px;
  position: absolute;
  top: 5px;
  width: 133px;
}

.subtract-1 {
  height: 154px;
  left: 47px;
  position: absolute;
  top: 169px;
  width: 125px;
}

.subtract-2 {
  height: 33px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}

.overlap-group1-1 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 8px;
  left: 98px;
  min-height: 93px;
  padding: 13.9px 22.7px;
  position: absolute;
  top: 118px;
  width: 140px;
}

.lead-rating {
  letter-spacing: 0;
  line-height: normal;
  margin-top: 9px;
  min-height: 24px;
  min-width: 94px;
  text-align: center;
}

.text-1 {
  align-self: center;
  letter-spacing: 0;
  line-height: normal;
  margin-left: 1.23px;
  min-height: 24px;
  min-width: 32px;
  text-align: center;
}

.overlap-group23 {
  align-items: flex-end;
  align-self: flex-end;
  background-color: var(--white);
  display: flex;
  height: 314px;
  justify-content: flex-end;
  margin-bottom: 5px;
  min-width: 492px;
  padding: 55px 36px;
}

.lorem-ipsum-dolor-si {
  letter-spacing: 0;
  line-height: normal;
  min-height: 200px;
  width: 401px;
}


/* Start calculator now! */

.overlap-group22 {
  height: 258px;
  margin-right: 2.0px;
  margin-top: 68px;
  position: relative;
  width: 1168px;

}
.leed-start-now{
  height: 10%;
  text-align: center;
  margin-right: 10%;
  font-size: 50px;
  
}
.start-now{
  font-size: 50px;
  text-align: center;
  margin-top: 2%;
}

.rectangle-184 {
  height: 258px;
  left: 594px;
  position: absolute;
  top: 0;
  width: 574px;
}

.rectangle-183 {
  background-color: #2566ffe3;
  height: 258px;
  left: 0;
  position: absolute;
  top: 0;
  width: 595px;
}

.frame-99 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 21px;
  left: 74px;
  min-height: 170px;
  position: absolute;
  top: 44px;
  width: 453px;
}

.title {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  min-height: 49px;
}

.lorem-ipsum-dolor-si-1 {
  letter-spacing: 0;
  line-height: normal;
  min-height: 100px;
  width: 453px;
  font-size: 16px !important;
}

.foundersgrotesk-regular-normal-white-20-9px {
    color: var(--white);
    font-family: var(--font-family-founders_grotesk-regular);
    font-size: var(--font-size-xl) !important;
    font-style: normal;
    font-weight: 400;
}

.overlap-group19 {
  align-self: flex-start;
  height: 750px;
  margin-left: -34px;
  margin-top: 99px;
  position: relative;
  width: 1546px;
}

.rectangle-176 {
  background: linear-gradient(180deg, rgb(97.75, 115.44, 255) 0%, rgba(141, 150, 221, 0.8) 100%);
  height: 537px;
  left: 32px;
  position: absolute;
  top: 50px;
  width: 1457px;
}

.ellipse-81 {
  border-radius: 269.5px/275.5px;
  height: 551px;
  left: 0;
  position: absolute;
  top: 107px;
  width: 539px;
}

.line-785 {
  height: 539px;
  left: 421px;
  position: absolute;
  top: 155px;
  width: 1px;
}

.line-786 {
  height: 1px;
  left: 0;
  position: absolute;
  top: 383px;
  width: 539px;
}

.rectangle-179 {
  height: 462px;
  left: 55px;
  position: absolute;
  top: 152px;
  width: 484px;
}

.line-787 {
  height: 1px;
  left: 535px;
  position: absolute;
  top: 153px;
  width: 1011px;
}

.line-788 {
  height: 131px;
  left: 539px;
  position: absolute;
  top: 24px;
  width: 1px;
}

.ellipse-82 {
  background-color: var(--white);
  border-radius: 6px;
  height: 12px;
  left: 415px;
  position: absolute;
  top: 377px;
  width: 12px;
}

.ellipse-83 {
  background-color: var(--white);
  border-radius: 6px;
  height: 12px;
  left: 533px;
  position: absolute;
  top: 146px;
  width: 12px;
}

.ellipse-86 {
  background-color: var(--white);
  border-radius: 6px;
  height: 12px;
  left: 1383px;
  position: absolute;
  top: 147px;
  width: 12px;
}

.ellipse-87 {
  background-color: var(--white);
  border-radius: 5px;
  height: 10px;
  left: 1172px;
  position: absolute;
  top: 327px;
  width: 10px;
}

.line-789 {
  height: 750px;
  left: 1388px;
  position: absolute;
  top: 0;
  width: 4px;
}

.ellipse-84 {
  border-radius: 189.92px;
  height: 380px;
  left: 1142px;
  position: absolute;
  top: 34px;
  width: 380px;
}

.ellipse-85 {
  border-radius: 120.2px;
  height: 240px;
  left: 986px;
  position: absolute;
  top: 269px;
  width: 240px;
}

.line-790 {
  height: 499px;
  left: 930px;
  position: absolute;
  top: 37px;
  width: 599px;
}

.overlap-group17 {
  align-items: flex-start;
  background-color: var(--white);
  box-shadow: 0px 4px 25px -6px #1033684a;
  display: flex;
  flex-direction: column;
  gap: 1px;
  left: 451px;
  min-height: 298px;
  padding: 52px 55px;
  position: absolute;
  top: 396px;
  width: 637px;
}

.want-to-know-more {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent;
  background: linear-gradient(180deg, rgb(130.69, 144.5, 255) 0%, rgba(107.31, 157.53, 255, 0.99) 100%);
  background-clip: text;
  letter-spacing: 0;
  line-height: normal;
  min-height: 48px;
  text-fill-color: transparent;
  width: 345px;
}

.group-1000006588 {
  align-items: flex-start;
  display: flex;
  gap: 24px;
  height: 132px;
  min-width: 463px;
}

.flex-col {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 31px;
  margin-top: -1px;
  min-height: 133px;
  width: 305px;
}

.join-the-waitlist {
  letter-spacing: 0;
  line-height: normal;
  min-height: 65px;
  width: 209px;
}

.enter-your-email-address {
  letter-spacing: 0;
  line-height: normal;
  margin-left: 0px;
  min-height: 22px;
  width: 230px;
}

.overlap-group-2 {
  align-items: flex-end;
  align-self: flex-end;
  background-color: var(--blueberry);
  display: flex;
  height: 54px;
  min-width: 130px;
  padding: 15px 31px;
}

.submit {
  letter-spacing: 0;
  line-height: normal;
  min-height: 22px;
  text-align: center;
  width: 68px;
}

