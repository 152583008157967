.group-1000006470-2 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
  left: 670px;
  min-height: 369px;
  position: absolute;
  top: 109px;
  width: 414px;
}

.overlap-group7-2 {
  height: 333px;
  position: relative;
  width: 414px;
}

.frame-20 {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 49px;
  left: 0;
  min-height: 333px;
  position: absolute;
  top: 0;
  width: 414px;
}

.group-1000006463 {
  align-items: flex-start;
  display: flex;
  gap: 18px;
  height: 15px;
  margin-right: -2px;
  min-width: 416px;
}

.number-2 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.08px;
  min-height: 15px;
  min-width: 22px;
  text-align: right;
}

.line {
  align-self: center;
  height: 1px;
  margin-top: 1.09px;
  width: 374px;
}

.group-1000006465 {
  align-items: flex-start;
  display: flex;
  gap: 18px;
  height: 15px;
  margin-right: -2.0px;
  min-width: 402px;
}

.number-1 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.08px;
  min-height: 15px;
  min-width: 8px;
  text-align: right;
}

.group-1000006459 {
  height: 240px;
  left: 73px;
  position: absolute;
  top: 44px;
  width: 308px;
}

.frame-21 {
  align-items: flex-start;
  display: flex;
  gap: 40px;
  height: 15px;
  margin-left: 33.42px;
  min-width: 344px;
}

.number-3 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.08px;
  min-height: 15px;
  min-width: 6px;
  text-align: right;
}
/* Set the background color of the dropdown items */
.dropdown-menu a {
  background-color: black; /* change this to the desired color */
  color: black; /* change this to the desired text color */
}

/* Set the background color of the active dropdown item */
.dropdown-menu .active a {
  background-color: black; /* change this to the desired active item color */
}

/* Set the background color of the navbar tabs */
.nav-item a {
  background-color: black; /* change this to the desired color */
  color: black; /* change this to the desired text color */
}

/* Set the background color of the active navbar tab */
.nav-item .active a {
  background-color: black; /* change this to the desired active tab color */
}

.a-navbar {
  text-decoration: underline;
  text-align: left;
  color: black;
}
.b-navbar {
  text-decoration: underline;
  text-align: left;
  color: black;
}


