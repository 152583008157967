.frame-61 {
  -webkit-backdrop-filter: blur(15.99px) brightness(100%);
  align-items: flex-start;
  backdrop-filter: blur(15.99px) brightness(100%);
  background: linear-gradient(180deg, rgb(43.56, 106.52, 255) 0%, rgba(54.67, 112.1, 247.56, 0.88) 74.67%);
  display: flex;
  height: 380px;
  margin-top: 92px;
  overflow: hidden;
  width: 1440px;
}

.line-740 {
  height: 1px;
  margin-top: 43px;
  width: 293px;
}

.group-1000006571 {
  height: 295px;
  margin-top: -4px;
  width: 152px;
}

.overlap-group-container-1 {
  align-self: flex-end;
  height: 342px;
  margin-bottom: -0.5px;
  position: relative;
  width: 658px;
}

.overlap-group2-1 {
  height: 342px;
  left: 191px;
  position: absolute;
  top: 0;
  width: 467px;
}

.group-container-3 {
  height: 82px;
  left: 27px;
  position: absolute;
  top: 43px;
  width: 110px;
}

.overlap-group-5 {
  height: 82px;
  left: 0;
  position: absolute;
  top: 0;
  width: 110px;
}

.vector-24-2 {
  height: 11px;
  left: 69px;
  position: absolute;
  top: 0;
  width: 13px;
}

.rectangle-95-2 {
  background-color: var(--white);
  height: 71px;
  left: 16px;
  position: absolute;
  top: 0;
  width: 54px;
}

.vector-25-2 {
  height: 16px;
  left: 93px;
  position: absolute;
  top: 23px;
  width: 16px;
}

.vector-23-2 {
  height: 17px;
  left: 0;
  position: absolute;
  top: 31px;
  width: 19px;
}

.rectangle-96-2 {
  background-color: var(--white);
  height: 73px;
  left: 40px;
  position: absolute;
  top: 9px;
  width: 55px;
}

.group-1000006420-2 {
  height: 77px;
  left: 7px;
  position: absolute;
  top: 3px;
  width: 96px;
}

.rectangle-162 {
  height: 165px;
  left: 0;
  position: absolute;
  top: 0;
  width: 164px;
  border: 1px solid white;
}

.vector-36 {
  height: 262px;
  left: 164px;
  position: absolute;
  top: 79px;
  width: 304px;
}

.overlap-group-6 {
  align-items: flex-end;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.13) 0%, rgba(255, 255, 255, 0.12) 100%);
  display: flex;
  height: 104px;
  justify-content: flex-end;
  left: 0;
  min-width: 558px;
  padding: 20px 24px;
  position: absolute;
  top: 199px;
  border: 1px solid white;
}

.an-ai-solution-to-ma {
  letter-spacing: 0;
  /* line-height: 135.5px; */
  min-height: 54px;
  /* white-space: nowrap; */
  width: 501px;
}

.frame-61.frame-61-1 {
  margin-top: 62px;
}

