.group-100000646 {
  align-items: flex-start;
  display: flex;
  gap: 18px;
  height: 15px;
  margin-right: -2.0px;
  min-width: 411px;
}

.number-4 {
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1.08px;
  min-height: 15px;
  min-width: 16px;
  text-align: right;
}

.line-1 {
  align-self: center;
  height: 1px;
  margin-top: 1.09px;
  width: 374px;
}

